<script setup lang="ts">
import type { ConcessionOrder } from '#gql/default'

interface Props {
  order: ConcessionOrder
}

const { order } = defineProps<Props>()

const { icons } = useDesign()
const { ct } = useCinemaStrings()

const { STATUS_READY } = ORDER_CONCESSIONS_CINEMA_STRINGS

defineOptions({
  name: 'OnSiteConcessionsCardStatusReady',
})
</script>

<template>
  <div>
    <Icon :name="icons.CHECK_CIRCLE_OUTLINE" class="size-8" />
    <div class="flex flex-col gap-2">
      <span v-text="ct(STATUS_READY)" />
      <span v-text="order.pickupCode" class="text-2xl font-bold" />
    </div>
  </div>
</template>
