<script setup lang="ts">
import type { CartConcession, Cinema, Concession } from '#gql/default'

interface Props {
  concession: Concession
  cinema: Cinema
}

const { concession, cinema } = defineProps<Props>()
const { t, n } = useI18n()
const isModalOpen = ref(false)

const { addToCart, getConcessionQuantity, getConcessionItems } =
  await useConcessionCart({
    cinema,
  })

const cartConcessions = computed(() => getConcessionItems(concession.id) ?? [])
const quantity = computed(() => getConcessionQuantity(concession.id))

const selectedQuantity = ref(quantity.value)
const selectedConcessions = ref<CartConcession[]>([])

async function handleQuickAddClick() {
  selectedQuantity.value = 1

  if (concession.subItems.length > 0) {
    isModalOpen.value = true
    selectedConcessions.value = []
    return
  }

  await addToCart({
    concession,
    items: [
      {
        concessionId: concession.id,
        quantity: quantity.value + 1,
      },
    ],
  })
}

function handleCardClick() {
  isModalOpen.value = true
  selectedConcessions.value = [...cartConcessions.value]
  selectedQuantity.value =
    cartConcessions.value.length === 1 ? quantity.value : 1
}

function handleVariationClick(id: string) {
  const selectedConcession = cartConcessions.value.find(
    (item) => item.id === id,
  )

  if (!selectedConcession) {
    return
  }

  selectedConcessions.value = [selectedConcession]
  selectedQuantity.value = selectedConcession.quantity
  isModalOpen.value = true
}

defineOptions({
  name: 'ConcessionCard',
})
</script>

<template>
  <div
    class="bg-body dark:bg-dark bg-light hover:bg-neutral-light-2/70 dark:hover:bg-neutral-dark-2/70 border-neutral-dark-1 md:dark:border-neutral-light-1 dark:bg-dark relative grid h-full cursor-pointer grid-cols-[minmax(0,1fr),25%] gap-x-4 overflow-hidden border-t px-[var(--container-padding)] py-4 transition-colors sm:px-[var(--container-padding-sm)] md:rounded-lg md:border md:px-4 lg:grid-cols-1 lg:grid-rows-[auto,minmax(0,1fr),auto] lg:gap-x-0 lg:rounded-lg lg:px-0 lg:pb-0 lg:pt-0"
    :title="t('btn.add', { concession: concession.name })"
    @click="handleCardClick"
  >
    <div class="order-2 lg:order-1 lg:col-span-2 lg:w-full">
      <ConcessionImage :concession />
      <ConcessionConfigureButtonQuickAdd
        :quantity
        :concession
        @click.stop="handleQuickAddClick"
      />
    </div>

    <div class="order-1 lg:order-2 lg:col-span-2 lg:p-4">
      <div
        class="font-bold leading-tight lg:mt-1.5 lg:text-lg"
        v-text="concession.name"
      />
      <div
        class="mt-1 text-sm"
        v-text="t('price', { price: n(concession.price.total, 'currency') })"
      />

      <div
        class="dark:text-neutral-light-1 text-neutral-dark-1 mt-4 text-sm lg:mt-2"
        v-html="concession.description"
      />
    </div>

    <div
      v-if="concession.subItems.length > 0 && cartConcessions.length > 0"
      class="order-3 col-span-2 mt-1 space-y-1 text-sm empty:hidden md:mt-4 lg:mt-0 lg:px-4 lg:pb-4"
    >
      <ConcessionConfigureButtonVariation
        v-for="cartConcession in cartConcessions"
        :key="cartConcession.id"
        :cart-concession
        @click.stop="handleVariationClick(cartConcession.id)"
      />
    </div>

    <ConcessionConfigureModal
      v-if="isModalOpen"
      :concession
      :cinema
      :selected-concessions
      :cart-concessions
      :quantity="selectedQuantity"
      @close="(selectedConcessions = []), (isModalOpen = false)"
    />
  </div>
</template>

<i18n>
de:
  price: "je {price}"
  btn:
    add: "{concession} hinzufügen"
en:
  price: "per {price}"
  btn:
    add: "{concession} add"
es:
  price: "por {price}"
  btn:
    add: "{concession} agregar"
</i18n>
