<script setup lang="ts">
import type { Cinema } from '#gql/default'
import { useRouteQuery } from '@vueuse/router'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()
const { t } = useI18n()
const searchTerm = useRouteQuery('search', '', { transform: String })

const faq = computed(() => {
  // Cleanup first and remove empty lines
  // Maybe needs to get adjusted if we have more crap in the faq
  const strippedHtml =
    cinema.faq?.replace(/<p>(&nbsp;|\s)*<\/p>/g, '').trim() ?? ''

  if (!searchTerm.value) {
    return strippedHtml
  }

  const escapedSearchTerm = searchTerm.value.replace(
    /[.*+?^${}()|[\]\\]/g,
    '\\$&',
  )
  const regex = new RegExp(escapedSearchTerm, 'gi')

  const replaceTextWithHighlight = (html: string): string => {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    const fragment: DocumentFragment = document.createDocumentFragment()

    function setTabindexForFocusableElements(
      node: Element | DocumentFragment,
    ): void {
      const focusableSelectors = [
        'a[href]',
        'area[href]',
        'input:not([disabled])',
        'select:not([disabled])',
        'textarea:not([disabled])',
        'button:not([disabled])',
        '[tabindex]:not([tabindex="-1"])',
      ]
      focusableSelectors.forEach((selector) => {
        node.querySelectorAll(selector).forEach((focusableElement: Element) => {
          if (!focusableElement.matches('.text-on-primary')) {
            focusableElement.setAttribute('tabindex', '-1')
          }
        })
      })
    }

    const walkAndReplace = (node: Element | DocumentFragment): void => {
      Array.from(node.childNodes).forEach((child: ChildNode) => {
        if (child.nodeType === 3 && regex.test(child.nodeValue || '')) {
          const newHtml = (child.nodeValue || '').replace(
            regex,
            (match: string) =>
              `<span tabindex="0" class="bg-primary text-on-primary">${match}</span>`,
          )
          const tempElement = document.createElement('div')
          tempElement.innerHTML = newHtml
          while (tempElement.firstChild) {
            fragment.appendChild(tempElement.firstChild)
          }
          node.replaceChild(fragment, child)
        } else if (
          child.nodeType === 1 &&
          child.nodeName !== 'SCRIPT' &&
          child.nodeName !== 'STYLE'
        ) {
          walkAndReplace(child as Element)
        }
      })
    }

    walkAndReplace(tempDiv)
    setTabindexForFocusableElements(tempDiv)
    return tempDiv.innerHTML
  }

  return replaceTextWithHighlight(strippedHtml!)
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugFaq',
})
</script>

<template>
  <PageGridColumn class="widget:pt-0 py-8">
    <FiltersWrapper>
      <FiltersSearch :placeholder="t('searchPlaceholder')" :collapse="false" />
    </FiltersWrapper>

    <div
      class="prose prose-p:my-1 prose-td:py-1.5 dark:prose-invert prose-hr:my-4 prose-strong:text-inherit prose-tr:border-neutral-light-1 dark:prose-tr:border-neutral-dark-1 max-w-none"
      v-html="faq"
    />
  </PageGridColumn>
</template>

<i18n>
de:
  searchPlaceholder: "FAQ durchsuchen"
es:
  searchPlaceholder: "Buscar FAQ"
</i18n>
