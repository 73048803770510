<script setup lang="ts">
import type { PaymentMethodName } from '@booking/types/payment-method'
import { submitForm, useFormKitContextById } from '@formkit/vue'
import useVoucherShop from '@voucher-shop/composables/use-voucher-shop'

const { cinema } = await useVoucherShop()
const { t } = useI18n()

const formId = useId()
const panelId = useId()

usePanel({
  id: panelId,
})

const selectedPayment = ref<PaymentMethodName | ''>('')

const context = useFormKitContextById(formId)
const isFormValid = computed(() => context.value?.state.valid)

const { cart } = await useVoucherCart({
  cinema: cinema.value,
})

const isPaymentSelectionDisabled = ref(!cart.value?.contact?.email)

const formData = ref({
  email: cart.value?.contact?.email,
  selectedPayment: '',
})

function handleMailChange(isValid: boolean) {
  formData.value.selectedPayment = ''
  isPaymentSelectionDisabled.value = !isValid
}

defineOptions({
  name: 'VoucherShopPanelCheckout',
})
</script>

<template>
  <Panel :id="panelId" data-panel-checkout>
    <template #default="{ cssClasses }">
      <div :class="cssClasses.grid">
        <CartExpirationTimer
          v-if="cart?.expiresAt"
          :expires-at="cart.expiresAt"
          :class="[cssClasses.sidebar, 'order-1 md:order-2']"
        />

        <CartOverview :class="[cssClasses.sidebar, 'order-3']" />

        <div :class="[cssClasses.content, 'order-2 md:order-1 md:row-span-4']">
          <FormKit :id="formId" v-model="formData" type="form" :actions="false">
            <div class="space-y-10">
              <CartContact
                @valid-mail="handleMailChange(true)"
                @invalid-mail="handleMailChange(false)"
              />

              <CartPaymentSelection
                v-model="selectedPayment"
                :disabled="isPaymentSelectionDisabled"
                :form-id="formId"
                class="transition-opacity duration-300"
              />
              <CartTermsOfService />
            </div>
          </FormKit>
        </div>

        <div
          id="checkout"
          :class="[
            cssClasses.content,
            {
              'order-last md:col-start-1': true,
              'pointer-events-none opacity-30': !isFormValid,
            },
          ]"
        >
          <KButton
            v-if="!selectedPayment"
            :text="t('btn.buyNow')"
            class="w-full"
            @click="submitForm(formId)"
          />
        </div>
      </div>
    </template>
  </Panel>
</template>

<i18n>
de:
  btn:
    buyNow: "Jetzt kaufen"
en:
  btn:
    buyNow: "Buy now"
es:
  btn:
    buyNow: "Comprar ahora"
</i18n>
