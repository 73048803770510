<script setup lang="ts">
import type { CartConcession, Cinema } from '#gql/default'
import { CONCESSION_SHOP_PANEL } from '@concession-shop/constants/concession-shop'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()
const { t } = useI18n()
const { setPanel } = useConcessionShopPanels()
const { cart } = await useConcessionCart({
  cinema,
})

const isModalOpen = ref(false)
const selectedConcessions = ref<CartConcession[]>([])

function handleEdit(cartConcession: CartConcession) {
  selectedConcessions.value = [cartConcession]
  isModalOpen.value = true
}

defineOptions({
  name: 'ConcessionShopPanelCart',
})
</script>

<template>
  <Panel>
    <template #default="{ cssClasses }">
      <div v-if="cart?.concessions.length" :class="cssClasses.grid">
        <CartExpirationTimer
          v-if="cart?.expiresAt"
          :expires-at="cart.expiresAt"
          :class="[cssClasses.sidebar, 'md:col-start-2']"
        />

        <div
          :class="[
            cssClasses.content,
            'md:col-start-1 md:row-span-2 md:row-start-1 md:w-full',
          ]"
        >
          <div
            class="dark:bg-neutral-dark-2 bg-neutral-light-1 text:dark divide-neutral-light-1 dark:divide-neutral-dark-1 divide-y rounded-md px-4 py-2"
          >
            <CartConcession
              v-for="cartConcession in cart?.concessions"
              :key="cartConcession.id"
              :cart-concession
              @edit="handleEdit(cartConcession)"
            />

            <ConcessionConfigureModal
              v-if="isModalOpen"
              :concession="selectedConcessions[0]?.concession"
              :cinema
              :selected-concessions
              :cart-concessions="cart?.concessions"
              :quantity="selectedConcessions[0]?.quantity"
              @close="(selectedConcessions = []), (isModalOpen = false)"
            />
          </div>
        </div>

        <div
          v-if="cart"
          :class="[cssClasses.sidebar, 'md:col-start-2 md:row-start-2']"
        >
          <CartPriceInfo v-if="cart" :price="cart.price" />
          <CartAvailablePaymentMethods
            :available-payment-methods="cart.availablePaymentMethods"
          />
          <KButton
            :text="t('btn.checkout')"
            class="w-full"
            @click="setPanel(CONCESSION_SHOP_PANEL.CHECKOUT)"
          />
        </div>
      </div>
      <CartEmpty v-else />
    </template>
  </Panel>
</template>

<i18n>
de:
  btn:
    checkout: "Kasse"
    back: "Zum Shop"
es:
  btn:
    checkout: "Pagar"
    back: "Volver al Shop"
</i18n>
