import { useElementSize, useDebounceFn } from '@vueuse/core'
import {
  TAILWIND_CONTAINER_BREAKPOINT,
  TAILWIND_CONTAINER_QUERY,
} from '@theme/constants/tailwind'
import { shallowRef, nextTick } from 'vue'

export interface SlideBreakpointConfig {
  slidesWidth?: number
  slidesPerPage?: number
  gapSize?: number
}

export type ContainerBreakpoint =
  (typeof TAILWIND_CONTAINER_BREAKPOINT)[keyof typeof TAILWIND_CONTAINER_BREAKPOINT]

export type ResponsiveBehaviour = Partial<
  Record<BreakpointValue | 'DEFAULT', SlideBreakpointConfig>
>

interface useHorizontalMediaScrollerOptions {
  slides: Ref<any[]>
  responsiveBehaviour: ResponsiveBehaviour
  wrapperRef: Ref<HTMLElement | undefined>
}

type BreakpointKey = keyof typeof TAILWIND_CONTAINER_BREAKPOINT
type BreakpointValue = (typeof TAILWIND_CONTAINER_BREAKPOINT)[BreakpointKey]

export default function useHorizontalMediaScrollerNew({
  slides,
  responsiveBehaviour,
  wrapperRef,
}: useHorizontalMediaScrollerOptions) {
  const { width: wrapperWidth } = useElementSize(wrapperRef)

  const isDragging = ref(false)
  const isAnimating = ref(false)
  const currentTranslateX = ref(0)
  const offset = ref(0)
  const startX = ref(0)
  const endX = ref(0)
  const deltaX = ref(0)
  const startY = ref(0)
  const deltaY = ref(0)
  const hasDetectedDirection = ref(false)
  const DIRECTION_LOCK_THRESHOLD = 10 // pixels to determine scroll direction

  const totalSlides = computed(() => slides.value?.length || 0)

  // Reset position when slides change
  watch(totalSlides, () => {
    currentTranslateX.value = 0
    offset.value = 0
  })

  const maxScrollPosition = computed(() => {
    const config = getCurrentBreakpointConfig()
    const result = (() => {
      if (!wrapperWidth.value) return 0

      const gridElement = wrapperRef.value?.querySelector(
        '[data-horizontal-media-scroller-grid]'
      )
      if (!gridElement) return 0

      const computedGap =
        parseFloat(getComputedStyle(gridElement).columnGap) || 0

      if (config?.slidesPerPage) {
        const slideElement = gridElement.querySelector(
          '[data-horizontal-media-scroller-slide]'
        )
        const slideWidth = slideElement
          ? parseFloat(getComputedStyle(slideElement).width)
          : 0

        const totalWidth =
          slideWidth * totalSlides.value + computedGap * (totalSlides.value - 1)

        return totalWidth - wrapperWidth.value
      }

      // If we have slidesPerPage config, calculate based on that
      if (config?.slidesWidth) {
        // Calculate total content width
        const totalContentWidth =
          config.slidesWidth * totalSlides.value +
          computedGap * (totalSlides.value - 1)

        // The maximum scroll should be the difference between total content and wrapper
        return totalContentWidth - wrapperWidth.value
      }

      return 0
    })()

    // Ensure we never return a negative value
    return Math.max(0, result)
  })

  // Add this helper function to calculate visible slides
  const getVisibleSlidesCount = () => {
    const config = getCurrentBreakpointConfig()
    if (!config?.slidesWidth || !wrapperWidth.value) return 1

    const gridElement = wrapperRef.value?.querySelector(
      '[data-horizontal-media-scroller-grid]'
    )
    if (!gridElement) return 1

    const computedGap = gridElement
      ? parseFloat(getComputedStyle(gridElement).columnGap)
      : 0

    // Calculate how many slides fit fully in the viewport
    return Math.floor(
      (wrapperWidth.value + computedGap) / (config.slidesWidth + computedGap)
    )
  }

  // Add helper to calculate centering offset
  const calculateCenteringOffset = (visibleSlides: number) => {
    const config = getCurrentBreakpointConfig()
    if (!config?.slidesWidth || !wrapperWidth.value) return 0

    const gridElement = wrapperRef.value?.querySelector(
      '[data-horizontal-media-scroller-grid]'
    )
    if (!gridElement) return 0

    const computedGap = parseFloat(getComputedStyle(gridElement).columnGap) || 0
    const totalVisibleWidth =
      (config.slidesWidth + computedGap) * visibleSlides - computedGap

    // Calculate the remaining space and divide by 2 for centering
    return Math.max(0, (wrapperWidth.value - totalVisibleWidth) / 2)
  }

  function handleSlide(
    direction: 'left' | 'right',
    targetPosition?: number
  ): void {
    const config = getCurrentBreakpointConfig()
    if (!config) return

    const currentPosition = Math.abs(currentTranslateX.value)
    const gridElement = wrapperRef.value?.querySelector(
      '[data-horizontal-media-scroller-grid]'
    )
    const slideElement = wrapperRef.value?.querySelector(
      '[data-horizontal-media-scroller-slide]'
    )
    if (!gridElement || !slideElement) {
      return
    }

    const gridGap = parseFloat(getComputedStyle(gridElement).columnGap) || 0
    const slideWidth = parseFloat(getComputedStyle(slideElement).width) || 0

    // Route to the appropriate sliding handler based on config
    let nextPosition: number = currentTranslateX.value

    if (config.slidesWidth) {
      nextPosition = handleSlideForSlidesWidth({
        direction,
        targetPosition,
        config,
        currentPosition,
        gridElement,
        gridGap,
        slideWidth,
        wrapperWidth: wrapperWidth.value,
      })
    } else if (config.slidesPerPage) {
      nextPosition = handleSlideForSlidesPerPage({
        direction,
        targetPosition,
        config,
        currentPosition,
        gridGap,
        slideWidth,
        wrapperWidth: wrapperWidth.value,
      })
    }

    // Apply common slide behavior
    isAnimating.value = true
    currentTranslateX.value = -Math.max(
      0,
      Math.min(nextPosition, maxScrollPosition.value)
    )

    setTimeout(() => {
      isAnimating.value = false
    }, 500)
  }

  function handleSlideForSlidesPerPage({
    direction,
    targetPosition,
    currentPosition,
    gridGap,
    slideWidth,
    wrapperWidth,
  }: {
    direction: 'left' | 'right'
    targetPosition: number | undefined
    config: SlideBreakpointConfig
    currentPosition: number
    gridGap: number
    slideWidth: number
    wrapperWidth: number
  }): number {
    if (targetPosition !== undefined) {
      return targetPosition
    }

    const pageWidth = wrapperWidth + gridGap

    const nextPosition =
      direction === 'right'
        ? currentPosition + pageWidth
        : currentPosition - pageWidth

    if (import.meta.dev) {
      console.log(
        'handleSlideForSlidesPerPage:',
        'direction',
        direction,
        'breakpoint',
        currentBreakpoint.value,
        'pageWidth',
        pageWidth,
        'gridGap',
        gridGap,
        'wrapperWidth',
        wrapperWidth,
        'slideWidth',
        slideWidth,
        'currentPosition',
        currentPosition,
        'nextPosition',
        nextPosition
      )
    }

    return nextPosition
  }

  function handleSlideForSlidesWidth({
    direction,
    targetPosition,
    config,
    currentPosition,
    gridGap,
    slideWidth,
    wrapperWidth,
  }: {
    direction: 'left' | 'right'
    targetPosition: number | undefined
    config: SlideBreakpointConfig
    currentPosition: number
    gridElement: Element
    gridGap: number
    slideWidth: number
    wrapperWidth: number
  }): number {
    const visibleSlides = getVisibleSlidesCount()
    const pageWidth = (config.slidesWidth! + gridGap) * visibleSlides
    const centeringOffset = calculateCenteringOffset(visibleSlides)

    if (targetPosition !== undefined) {
      return targetPosition
    }

    // Calculate total content width
    const totalContentWidth =
      config.slidesWidth! * totalSlides.value +
      gridGap * (totalSlides.value - 1)

    // Check if we're at the last slide
    const isAtLastSlide =
      currentPosition >= totalContentWidth - wrapperWidth - 1

    if (direction === 'left' && isAtLastSlide) {
      // Calculate the position for the previous centered page
      const previousPageStart = currentPosition - pageWidth
      return previousPageStart + centeringOffset
    }

    // Normal sliding behavior
    const truePosition =
      currentPosition === 0
        ? currentPosition
        : currentPosition + centeringOffset

    const nextPosition =
      direction === 'right'
        ? truePosition + pageWidth
        : truePosition - pageWidth

    const finalPosition = nextPosition - centeringOffset

    if (import.meta.dev) {
      console.log(
        'handleSlideForSlidesWidth:',
        'direction',
        direction,
        'breakpoint',
        currentBreakpoint.value,
        'pageWidth',
        pageWidth,
        'gridGap',
        gridGap,
        'wrapperWidth',
        wrapperWidth,
        'slideWidth',
        slideWidth,
        'visibleSlides',
        visibleSlides,
        'centeringOffset',
        centeringOffset,
        'currentPosition',
        currentPosition,
        'truePosition',
        truePosition,
        'nextPosition',
        nextPosition,
        'finalPosition',
        finalPosition,
        'isAtLastSlide',
        isAtLastSlide
      )
    }

    return finalPosition
  }

  function slideRight(): void {
    if (!canSlideRight.value || isAnimating.value) {
      return
    }

    handleSlide('right')
  }

  function slideLeft(): void {
    if (!canSlideLeft.value || isAnimating.value) {
      return
    }

    handleSlide('left')
  }

  function onTouchStart(event: TouchEvent) {
    isDragging.value = false // Don't start dragging immediately
    hasDetectedDirection.value = false
    startX.value = Math.floor(event.touches[0].clientX)
    startY.value = Math.floor(event.touches[0].clientY)
    deltaX.value = 0
    deltaY.value = 0
  }

  function onTouchMove(event: TouchEvent) {
    const currentX = Math.floor(event.touches[0].clientX)
    const currentY = Math.floor(event.touches[0].clientY)

    deltaX.value = currentX - startX.value
    deltaY.value = currentY - startY.value

    // If we haven't determined the scroll direction yet
    if (!hasDetectedDirection.value) {
      // Check if we've moved enough to determine direction
      if (
        Math.abs(deltaX.value) > DIRECTION_LOCK_THRESHOLD ||
        Math.abs(deltaY.value) > DIRECTION_LOCK_THRESHOLD
      ) {
        // If horizontal movement is greater, this is a slide gesture
        if (Math.abs(deltaX.value) > Math.abs(deltaY.value)) {
          isDragging.value = true
          hasDetectedDirection.value = true
        } else {
          // Vertical scroll, don't initiate dragging
          hasDetectedDirection.value = true
          return
        }
      }
      return
    }

    // Only proceed with horizontal scrolling if we're dragging
    if (!isDragging.value) {
      return
    }

    endX.value = currentX

    const potentialPosition = currentTranslateX.value + deltaX.value

    if (potentialPosition > 0) {
      offset.value = deltaX.value * 0.3
    } else if (potentialPosition < -maxScrollPosition.value) {
      const overscroll = potentialPosition + maxScrollPosition.value
      offset.value =
        -maxScrollPosition.value - currentTranslateX.value + overscroll * 0.3
    } else {
      offset.value = deltaX.value
    }
  }

  function onTouchEnd() {
    if (!isDragging.value) {
      // Reset all values
      offset.value = 0
      startX.value = 0
      endX.value = 0
      deltaX.value = 0
      startY.value = 0
      deltaY.value = 0
      hasDetectedDirection.value = false
      return
    }

    isDragging.value = false
    hasDetectedDirection.value = false

    const config = getCurrentBreakpointConfig()
    if (!config) return

    handleSlide(deltaX.value > 0 ? 'left' : 'right')

    // Reset values
    offset.value = 0
    startX.value = 0
    endX.value = 0
    deltaX.value = 0
    startY.value = 0
    deltaY.value = 0
  }

  const canSlideLeft = computed(() => {
    return currentTranslateX.value < 0
  })

  const canSlideRight = computed(() => {
    const currentPosition = Math.abs(currentTranslateX.value)
    const maxScroll = maxScrollPosition.value

    return currentPosition < maxScroll
  })

  const position = computed(() => {
    let pos = currentTranslateX.value + (offset.value || 0)

    // Ensure we don't scroll past content
    const boundedPos = Math.max(
      -Math.max(0, maxScrollPosition.value), // Never go below 0
      Math.min(0, pos)
    )

    return boundedPos
  })

  const cssVariables = computed(() => {
    const variables: Record<string, string> = {}
    const breakpoints = Object.keys(
      TAILWIND_CONTAINER_BREAKPOINT
    ) as BreakpointKey[]

    // Set default variables with fallback values
    const defaultConfig = responsiveBehaviour['DEFAULT'] || {
      slidesWidth: 200,
      gapSize: 16,
    }

    // Always set default variables regardless of DEFAULT config
    variables['--slide-gap'] = `${defaultConfig.gapSize}px`

    if (defaultConfig.slidesWidth) {
      variables['--slide-width'] = `${defaultConfig.slidesWidth}px`
    } else if (defaultConfig.slidesPerPage) {
      variables['--slide-width'] =
        `calc((100% - ((${defaultConfig.slidesPerPage} - 1) * var(--slide-gap))) / ${defaultConfig.slidesPerPage})`
    }

    // Then set breakpoint-specific variables
    breakpoints.forEach((breakpoint) => {
      const config = getConfigForBreakpoint(breakpoint)
      if (config) {
        const breakpointValue = TAILWIND_CONTAINER_BREAKPOINT[breakpoint]

        if (config.gapSize) {
          variables[`--slide-gap-${breakpointValue}`] = `${config.gapSize}px`
        }

        if (config.slidesWidth) {
          variables[`--slide-width-${breakpointValue}`] =
            `${config.slidesWidth}px`
        } else if (config.slidesPerPage) {
          // Fix the calculation for slidesPerPage
          variables[`--slide-width-${breakpointValue}`] =
            `calc((100% - ((${config.slidesPerPage} - 1) * var(--slide-gap-${breakpointValue}, var(--slide-gap)))) / ${config.slidesPerPage})`
        }

        // Calculate grid width based on configuration type
        if (config.slidesWidth) {
          variables[`--grid-width-${breakpointValue}`] =
            `calc(var(--slide-width-${breakpointValue}) * ${totalSlides.value} + ((${totalSlides.value} - 1) * var(--slide-gap-${breakpointValue}, var(--slide-gap))))`
        } else if (config.slidesPerPage) {
          // For slidesPerPage, grid width should be 100%
          variables[`--grid-width-${breakpointValue}`] = '100%'
        }
      }
    })

    return variables
  })

  function getConfigForBreakpoint(breakpoint: BreakpointKey) {
    const config =
      responsiveBehaviour[TAILWIND_CONTAINER_BREAKPOINT[breakpoint]]
    if (!config) {
      const breakpoints = Object.keys(
        TAILWIND_CONTAINER_BREAKPOINT
      ) as BreakpointKey[]
      const index = breakpoints.indexOf(breakpoint)
      for (let i = index - 1; i >= 0; i--) {
        const smallerConfig =
          responsiveBehaviour[TAILWIND_CONTAINER_BREAKPOINT[breakpoints[i]]]
        if (smallerConfig) {
          return smallerConfig
        }
      }
    }
    return config
  }

  function getCurrentBreakpointConfig(): SlideBreakpointConfig | undefined {
    const width = wrapperWidth.value
    const breakpoints = Object.keys(
      TAILWIND_CONTAINER_BREAKPOINT
    ) as BreakpointKey[]

    for (let i = breakpoints.length - 1; i >= 0; i--) {
      const breakpoint = breakpoints[i]
      const breakpointValue = TAILWIND_CONTAINER_BREAKPOINT[breakpoint]
      const sizeKey =
        `SIZE_${breakpointValue.toUpperCase()}` as keyof typeof TAILWIND_CONTAINER_QUERY
      const breakpointSize = TAILWIND_CONTAINER_QUERY[sizeKey]

      if (width >= breakpointSize) {
        return getConfigForBreakpoint(breakpoint)
      }
    }

    return getConfigForBreakpoint('BREAKPOINT_XS')
  }

  function recalculatePositionOnResize() {
    const config = getCurrentBreakpointConfig()
    if (!config?.slidesPerPage) return

    // Get fresh values after resize
    const currentPosition = Math.abs(currentTranslateX.value)
    const maxScroll = maxScrollPosition.value

    // If we have fewer slides than can fit, reset to 0
    if (maxScroll <= 0) {
      isAnimating.value = false
      currentTranslateX.value = 0
      return
    }

    // Only recalculate if we were scrolled beyond new max
    if (currentPosition > maxScroll) {
      isAnimating.value = false
      currentTranslateX.value = -maxScroll
    }
  }

  // Initialize currentBreakpoint as a shallowRef with 'default'
  const currentBreakpoint = shallowRef('default')

  // Move the breakpoint calculation logic to a separate function
  function calculateBreakpoint(size: number): string {
    if (!size) {
      return 'default'
    }

    const breakpoints = Object.keys(
      TAILWIND_CONTAINER_BREAKPOINT
    ) as BreakpointKey[]

    for (let i = breakpoints.length - 1; i >= 0; i--) {
      const breakpoint = breakpoints[i]
      const breakpointValue = TAILWIND_CONTAINER_BREAKPOINT[breakpoint] // e.g., 'xs', 'sm'
      const sizeKey =
        `SIZE_${breakpointValue.toUpperCase()}` as keyof typeof TAILWIND_CONTAINER_QUERY

      if (size >= TAILWIND_CONTAINER_QUERY[sizeKey]) {
        return breakpointValue
      }
    }

    return 'default'
  }

  const updateSlideWidths = () => {
    if (!wrapperRef.value || !import.meta.client) {
      return
    }

    const gridElement = wrapperRef.value.querySelector(
      '[data-horizontal-media-scroller-grid]'
    )

    const width = wrapperWidth.value

    if (!gridElement || !width) {
      return
    }
    // Update slide widths based on actual container width
    const style = (gridElement as HTMLElement).style
    const currentConfig = getCurrentBreakpointConfig()
    if (currentConfig?.slidesPerPage) {
      const gapSize = currentConfig.gapSize || 0
      const slideWidth =
        (width - (currentConfig.slidesPerPage - 1) * gapSize) /
        currentConfig.slidesPerPage
      style.setProperty('--slide-width', `${slideWidth}px`)
    }
  }

  // Debounce the width updates
  const updateOnResize = useDebounceFn(async (width: number) => {
    if (width > 0) {
      recalculatePositionOnResize()
      if (import.meta.client) {
        try {
          currentBreakpoint.value = calculateBreakpoint(width)
          await nextTick()
          updateSlideWidths()
        } catch (error) {
          console.warn('Error updating widths:', error)
          currentBreakpoint.value = 'default'
        }
      }
    }
  }, 150) // 150ms debounce

  // Update the watcher to use the debounced function
  watch(
    wrapperWidth,
    (width) => {
      updateOnResize(width)
    },
    { immediate: true }
  )

  return {
    position,
    isDragging,
    isAnimating,
    onTouchStart,
    slideLeft,
    slideRight,
    onTouchEnd,
    onTouchMove,
    canSlideLeft,
    canSlideRight,
    cssVariables,
    currentBreakpoint,
  }
}
