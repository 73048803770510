import type { ConcessionOrder, Cinema } from '#gql/default'

type OmittedConcessionOrder = Omit<ConcessionOrder, 'cinema'>

export const ORDER_CONCESSIONS_CARD_STATUS = {
  PREORDERED: 'PREORDERED',
  PREPARE: 'PREPARE',
  IN_PREPARATION: 'IN_PREPARATION',
  READY: 'READY',
  COMPLETE: 'COMPLETE',
  EXPIRED: 'EXPIRED',
}

export default function useOrderConcessions() {
  const orders = useState<OmittedConcessionOrder[]>(
    'concession-orders',
    () => [],
  )
  const orderConcessionsStringsLoaded = useState<boolean>(
    'orderConcessionsStringsLoaded',
    () => false,
  )

  const { fetchCinemaStrings } = useCinemaStrings()
  const { orders: userOrders, fetchUserOrders } = useUserOrders()

  async function fetchOrderBySecurityKeyAndOrderNumber(
    securityKey: string,
    orderNumber: string,
  ) {
    try {
      const response = await GqlFetchConcessionOrderBySecurityKeyAndOrderNumber(
        {
          securityKey,
          orderNumber,
        },
      )

      if (
        response.order &&
        !orders.value.find((order) => order.id === response.order.id)
      ) {
        orders.value.push(response.order)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function fetchOrderByPickupCode(pickupCode: string, cinemaId: string) {
    try {
      const response = await GqlFetchConcessionOrderByPickupCode({
        pickupCode,
        cinemaId,
      })

      if (
        response.concessionOrder &&
        !orders.value.find((order) => order.id === response.concessionOrder.id)
      ) {
        // Temp Fix
        let tempOrder = response.concessionOrder

        if (!tempOrder.pickupCode || tempOrder.pickupCode.length === 0) {
          tempOrder.pickupCode = pickupCode
        }

        orders.value.push(tempOrder)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function fetchOrderByUser() {
    try {
      await fetchUserOrders()

      if (userOrders.value && userOrders.value.length > 0) {
        const newOrders = userOrders.value.filter(
          (userOrder) =>
            !orders.value.find((order) => order.id === userOrder.id),
        )
        orders.value.push(...newOrders)
      }
    } catch (error) {
      console.error(error)
    }
  }

  function removeOrderById(id: string) {
    orders.value = orders.value.filter((order) => order.id !== id)
  }

  async function startPreparation(id: string, cinemaId: string) {
    const order = orders.value.find((order) => order.id === id)

    if (order) {
      try {
        const response = await GqlConcessionOrderStartPreparation({
          pickupCode: order.pickupCode,
          cinemaId: cinemaId,
        })

        if (response.concessionOrderStartPreparation) {
          let index = orders.value.findIndex((order) => order.id === id)
          if (index !== -1) {
            orders.value[index].concessionStatus =
              response.concessionOrderStartPreparation.concessionStatus
          } else {
            console.log('order not found')
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  async function ensureOrderConcessionsStrings({
    cinema,
  }: {
    cinema?: Cinema
  }) {
    const { COMPLETE, EXPIRED, IN_PREPARATION, PREPARE, PREORDERED, READY } =
      ORDER_CONCESSIONS_CARD_STATUS

    if (orderConcessionsStringsLoaded.value) {
      return
    }

    await fetchCinemaStrings({
      keys: [COMPLETE, EXPIRED, IN_PREPARATION, PREPARE, PREORDERED, READY],
      cinemaId: cinema?.id,
    })

    orderConcessionsStringsLoaded.value = true
  }

  return {
    orders,
    fetchOrderBySecurityKeyAndOrderNumber,
    fetchOrderByPickupCode,
    fetchOrderByUser,
    removeOrderById,
    startPreparation,
    ensureOrderConcessionsStrings,
    ORDER_CONCESSIONS_CARD_STATUS,
  }
}
