import { useRouteQuery } from '@vueuse/router'
import {
  VOUCHER_SHOP_QUERY_PARAM,
  VOUCHER_SHOP_PANEL,
} from '@voucher-shop/constants/voucher-shop'
import type { Panel } from '@booking/types/panel-manager'
import type {
  VoucherShopData,
  VoucherShopStepKey,
} from '@voucher-shop/types/voucher-shop'
import type { Cinema, VoucherProduct, VoucherProductType } from '#gql/default'

export default async function useVoucherShop() {
  const { STEP, VOUCHER_ID, CART_VOUCHER_ID } = VOUCHER_SHOP_QUERY_PARAM
  const { CONFIGURE, SELECT } = VOUCHER_SHOP_PANEL

  const route = useRoute()
  const router = useRouter()
  const step = useRouteQuery<VoucherShopStepKey | undefined>(STEP)
  const voucherId = useRouteQuery<string | undefined>(VOUCHER_ID)
  const cartVoucherId = useRouteQuery<string | undefined>(CART_VOUCHER_ID)

  const voucherShopData = useState<VoucherShopData>('voucherShopData', () => ({
    voucherProducts: [],
    voucherProductTypes: [],
    isInitalised: false,
    panels: [],
  }))

  /**
   * Initialise the voucher shop with the specified cinema and voucher product types and current panel.
   * If the voucher products are not set, the function will fetch the voucher products first.
   */
  async function initialise({
    cinema,
    voucherProductTypes,
    panels,
  }: {
    cinema?: Cinema
    voucherProductTypes?: VoucherProductType[]
    panels: Panel[]
  }) {
    const result = await GqlFetchVoucherProducts({
      cinemaId: cinema?.id,
      types: voucherProductTypes,
    })

    voucherShopData.value = {
      ...voucherShopData.value,
      voucherProducts: result.voucherProducts as VoucherProduct[],
      cinema,
      voucherProductTypes,
      isInitalised: true,
      panels: panels.map((panel) => ({
        ...panel,
        hideNav: panel.key === CONFIGURE && result.voucherProducts.length === 1,
      })),
    }

    const { cart, fetchCart } = await useVoucherCart({
      cinema,
    })

    if (!cart.value && route.query.cartId) {
      await fetchCart(route.query.cartId as string)
    }

    if (
      !Object.values(VOUCHER_SHOP_PANEL).includes(
        step.value as VoucherShopStepKey,
      )
    ) {
      setInitialPanel()
      return
    }

    if (
      step.value !== CONFIGURE ||
      (!voucherId.value && !cartVoucherId.value)
    ) {
      setPanel(step.value as VoucherShopStepKey)
      return
    }

    if (!voucherId.value && !cartVoucherId.value) {
      setInitialPanel()
      return
    }

    setPanel(CONFIGURE, {
      voucherId: voucherId.value,
      cartVoucherId: cartVoucherId.value,
    })
  }

  function getVoucherProduct(voucherId: string) {
    return voucherShopData.value.voucherProducts.find(
      ({ id }) => voucherId === id,
    )
  }

  /**
   * Set the initial panel based on the number of voucher products.
   * If there is only one voucher product, it will be configured automatically.
   * Otherwise, the SELECT panel will be set.
   */
  function setInitialPanel() {
    // First set SELECT panel to establish navigation history
    setPanel(SELECT)

    // Then check if we should auto-configure
    if (voucherShopData.value.voucherProducts.length === 1) {
      const voucher = voucherShopData.value.voucherProducts[0]
      setPanel(CONFIGURE, { voucherId: voucher.id })
    }
  }

  /**
   * Set the panel based on the next panel key.
   * If the next panel is not valid, the initial panel will be set.
   * The panel will be set with the voucher ID or cart voucher ID if the next panel is CONFIGURE.
   */
  function setPanel(
    nextPanel?: VoucherShopStepKey,
    options?: {
      voucherId?: string
      cartVoucherId?: string
    },
  ) {
    if (!nextPanel || !Object.values(VOUCHER_SHOP_PANEL).includes(nextPanel)) {
      setInitialPanel()
      return
    }

    const query: Record<string, string | undefined> = {
      ...route.query,
      [STEP]: nextPanel,
      [VOUCHER_ID]: nextPanel === CONFIGURE ? options?.voucherId : undefined,
      [CART_VOUCHER_ID]:
        nextPanel === CONFIGURE ? options?.cartVoucherId : undefined,
    }

    router.push({
      query,
    })
  }

  return {
    step,
    panels: computed<Panel[]>(() => voucherShopData.value.panels),
    activePanel: computed<Panel | undefined>(() => {
      const panel = voucherShopData.value.panels.find(
        (panel) => panel.key === step.value,
      )
      if (!panel) return undefined

      if (panel?.key === CONFIGURE) {
        return {
          ...panel,
          title: voucherShopData.value.voucherProducts.find(
            (voucherProduct) => voucherProduct.id === voucherId.value,
          )?.name,
        }
      }

      if (!panel || !voucherShopData.value.isInitalised) {
        return undefined
      }

      return panel as Panel
    }),
    initialise,
    setPanel,
    setInitialPanel,
    voucherProducts: computed<VoucherProduct[]>(
      () => voucherShopData.value.voucherProducts,
    ),
    cinema: computed<Cinema | undefined>(() => voucherShopData.value.cinema),
    voucherId,
    cartVoucherId,
    isInitalised: computed<boolean>(() => voucherShopData.value.isInitalised),
    getVoucherProduct,
  }
}
