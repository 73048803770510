<script setup lang="ts">
import type { FormKitNode } from '@formkit/core'

const { t } = useI18n()
const { setEmailContact, cart } = await useCart()
const { add: addMessage } = useMessage()
const emit = defineEmits(['valid-mail', 'invalid-mail'])

const email = ref(cart.value?.contact?.email || '')
const inputId = useId()
const lastProcessedValue = ref(cart.value?.contact?.email || '')

async function updateEmail(newEmail: string) {
  try {
    const updatedEmail = await setEmailContact({
      email: newEmail,
    })

    if (updatedEmail) {
      emit('valid-mail', updatedEmail)
      lastProcessedValue.value = updatedEmail
    }
  } catch {
    addMessage({
      message: t('error'),
      severity: MESSAGE_SEVERITY.ERROR,
      type: MESSAGE_TYPE.TOAST,
    })
  }
}

function setNode(node: FormKitNode) {
  if (!node.context) return

  node.on('settled:validating', () => {
    const currentValue = node._value as string
    const isValid = node.context?.state.valid

    if (!isValid) {
      emit('invalid-mail')
      return
    }

    if (currentValue !== lastProcessedValue.value) {
      updateEmail(currentValue)
    } else if (currentValue === cart.value?.contact?.email) {
      emit('valid-mail', currentValue)
    }
  })
}

defineOptions({
  name: 'CartContact',
})
</script>

<template>
  <div>
    <BookingHeading :title="t('contact')" class="mb-2" />
    <FormKit
      :id="inputId"
      v-model="email"
      type="email"
      name="email"
      :floating-label="true"
      :label="t('email')"
      autocomplete="email"
      :help="t('help')"
      validation="required|email"
      :debounce="200"
      @node="setNode"
    />
  </div>
</template>

<i18n>
de:
  contact: "Kontakt"
  email: "E-Mail-Adresse"
  help: "Die angegebene E-Mail-Adresse wird zum Versand der Bestellung und der Bestellbestätigung verwendet. Einer Verwendung zur weiteren Kundenansprache nach § 7 Abs. 2 Nr. 3 UWG kann jederzeit kostenlos widersprochen werden."
  error: "Fehler beim Speichern der E-Mail-Adresse"
es:
  contact: "Contacto"
  email: "Correo electrónico"
  help: "La dirección de correo electrónico se utiliza para enviar la orden y la confirmación de la orden. El uso para contactar al cliente después de § 7 Abs. 2 Nr. 3 UWG puede ser retirado en cualquier momento de forma gratuita."
  error: "Error al guardar el correo electrónico"
</i18n>
