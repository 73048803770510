<script setup lang="ts">
const route = useRoute()
const step = computed(() => route.query.step ?? '')

defineOptions({
  name: 'PanelManager',
})
</script>

<template>
  <div data-panel-manager>
    <div data-panel-manager-navigation class="py-2 empty:hidden">
      <slot name="navigation" :step />
    </div>
    <div data-panel-manager-panels>
      <slot name="panels" :step />
    </div>
  </div>
</template>
