<script setup lang="ts">
import type { VoucherProduct } from '#gql/default'

interface Props {
  voucherProduct: VoucherProduct
}

function handleClick() {
  setPanel(VOUCHER_SHOP_PANEL.CONFIGURE, {
    voucherId: voucherProduct.id,
  })
}

const { voucherProduct } = defineProps<Props>()
const { setPanel } = await useVoucherShop()
const { t } = useI18n()

defineOptions({
  name: 'VoucherProductCard',
})
</script>

<template>
  <BookingCard
    :title="voucherProduct.name"
    :description="voucherProduct.description"
    @click="handleClick"
  >
    <template #image>
      <VoucherProductImage :voucher-product="voucherProduct" />
    </template>
    <template #footer>
      <KButton
        :size="BUTTON_SIZE.SM"
        :text="t('btn.configureVoucher')"
        class="w-full"
      />
    </template>
  </BookingCard>
</template>

<i18n>
de:
  btn:
    configureVoucher: "Gutschein konfigurieren"
en:
  btn:
    configureVoucher: "Configure voucher"
es:
  btn:
    configureVoucher: "Configurar cupón"
</i18n>
