<script setup lang="ts">
import type {
  CartConcession,
  CartConcessionInput,
  Cinema,
  Concession,
} from '#gql/default'

interface Props {
  concession: Concession
  cinema: Cinema
  cartConcessions?: CartConcession[]
  selectedConcessions?: CartConcession[]
  quantity?: number
}

const {
  concession,
  cinema,
  cartConcessions = [],
  selectedConcessions = [],
  quantity = 1,
} = defineProps<Props>()
const emit = defineEmits(['close', 'update'])

const { t, n } = useI18n()

const { addToCart, pending } = await useConcessionCart({
  cinema,
})

const { close, cssClasses, open } = useModal({
  onClose: () => {
    emit('close')
  },
})

open()

function createSubItemHash(item: CartConcession | CartConcessionInput): string {
  const subItemSelection =
    'subItemSelection' in item ? item.subItemSelection : []
  if (!subItemSelection?.length) {
    return ''
  }

  return subItemSelection
    .map((selection) => {
      // Handle CartConcessionInput type
      if ('subItemId' in selection) {
        const parts = [selection.subItemId]
        if (selection.optionId) {
          parts.push(selection.optionId)
        }
        return parts.join('-')
      }
      // Handle CartConcessionSubItem type
      const parts = [selection.subItem.id]
      if (selection.option?.id) {
        parts.push(selection.option.id)
      }
      return parts.join('-')
    })
    .join('-')
}

function calculateQuantity(item: CartConcessionInput) {
  const items = cartConcessions.map((cartItem) => ({
    ...cartItem,
    subItemSelectionHash: createSubItemHash(cartItem),
  }))

  const subItemSelectionHash = createSubItemHash(item)
  const existingItem = items.find(
    (cartItem) => cartItem.subItemSelectionHash === subItemSelectionHash,
  )

  if (existingItem) {
    return existingItem.quantity + (item?.quantity ?? 1)
  }

  return item.quantity
}

async function handleUpdate(items: any) {
  if (pending.value) {
    return
  }

  await addToCart({
    concession,
    items: items.map((item: any) => ({
      concessionId: concession.id,
      quantity: !selectedConcessions.length
        ? calculateQuantity(item)
        : item.quantity,
      subItemSelection: item.subItemSelection,
    })),
  })

  close()
}

defineOptions({
  name: 'ConcessionConfigureModal',
})
</script>

<template>
  <Teleport to="body">
    <div :class="cssClasses.container">
      <div :class="cssClasses.modal" class="relative sm:!max-w-xl">
        <div class="space-y-2 pb-6 pt-1">
          <div class="text-xl font-bold" v-text="concession.name" />
          <div
            v-text="
              t('price', { price: n(concession.price.total, 'currency') })
            "
          />
          <div
            class="dark:text-neutral-light-1"
            v-html="concession.description"
          />
          <ButtonClose :class="cssClasses.close" @click="close()" />
        </div>

        <ConcessionConfigureForm
          :concession
          :selected-concessions
          :quantity
          @update="handleUpdate"
        />
      </div>
      <div :class="cssClasses.backdrop" @click="close" />
    </div>
  </Teleport>
</template>

<i18n>
de:
  price: "je {price}"
en:
  price: "per {price}"
es:
  price: "por {price}"
</i18n>
