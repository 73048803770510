<script setup lang="ts">
import type { Cinema, ConcessionOrder } from '#gql/default'
import { PAGE_HEADING_TAG } from '@base/constants/page'
import { BUTTON_TYPE } from '@kh-ui/constants/button'
import Masonry from '@yeger/vue-masonry-wall'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()

const { t } = useI18n()
const route = useRoute()

const {
  orders,
  fetchOrderByPickupCode,
  fetchOrderBySecurityKeyAndOrderNumber,
  fetchOrderByUser,
} = useOrderConcessions()

await fetchOrderByUser()

if (route.query.pickupCode) {
  await fetchOrderByPickupCode(route.query.pickupCode as string, cinema.id)
} else if (route.query.securityKey && route.query.orderNumber) {
  await fetchOrderBySecurityKeyAndOrderNumber(
    route.query.securityKey as string,
    route.query.orderNumber as string,
  )
}

const formData = ref({
  pickupCode: '',
})

async function submit() {
  if (formData.value.pickupCode) {
    await fetchOrderByPickupCode(formData.value.pickupCode, cinema.id)
  }
}

defineOptions({
  name: 'OnSiteConcessions',
})
</script>

<template>
  <div class="flex flex-col gap-4">
    <PageHeading
      class="text-center"
      :title="t('title')"
      :tag="PAGE_HEADING_TAG.H3"
    />

    <FormKit
      type="form"
      :actions="false"
      @submit="submit"
      v-model="formData"
      :classes="{ form: 'flex flex-row gap-4' }"
    >
      <FormKit
        type="text"
        name="pickupCode"
        :placeholder="t('collectCode')"
        :classes="{
          outer: 'flex-grow',
          input: 'w-full',
        }"
      />
      <KButton :type="BUTTON_TYPE.SUBMIT" :text="t('submit')" />
    </FormKit>

    <ClientOnly>
      <Masonry
        v-if="orders.length"
        :items="orders"
        :gap="16"
        :key="orders.length"
      >
        <template #default="{ item: order }">
          <OnSiteConcessionsCard :order="order as ConcessionOrder" />
        </template>
      </Masonry>
    </ClientOnly>
  </div>
</template>

<i18n>
de:
  title: "Einfach die Bestellnummer eingeben, um den Bestellstatus zu prüfen oder die Zubereitung vor Ort zu starten."
  submit: "Bestellung prüfen"
  collectCode: "Abholnummer"
en:
  title: "Enter the pickup code to check the order status or start the preparation at the concession."
  submit: "Check order"
  collectCode: "Pickup code"
es:
  title: "Ingrese el código de recogida para verificar el estado de la orden o iniciar la preparación en la tienda de alimentos."
  submit: "Verificar orden"
  collectCode: "Código de recogida"
</i18n>
