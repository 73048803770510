<script setup lang="ts">
import type {
  Cinema,
  VoucherProductType,
  VoucherShopStrings,
} from '#gql/default'
import type { Panel } from '@booking/types/panel-manager'
import { VOUCHER_SHOP_PANEL } from '@voucher-shop/constants/voucher-shop'

interface Props {
  cinema?: Cinema
  strings?: VoucherShopStrings
  voucherProductTypes?: VoucherProductType[]
}

const { t } = useI18n()
const { CONFIGURE, CART, CHECKOUT, SELECT } = VOUCHER_SHOP_PANEL
const { cinema, strings, voucherProductTypes } = defineProps<Props>()
const {
  voucherProducts,
  initialise,
  isInitalised,
  panels,
  activePanel,
  setPanel,
  setInitialPanel,
} = await useVoucherShop()

await initialise({
  cinema,
  voucherProductTypes,
  panels: [
    {
      key: SELECT,
      title: t('panels.select.title'),
      hideNav: true,
    },
    {
      key: CONFIGURE,
      title: t('panels.configure.title'),
      hideFromNav: true,
    },
    {
      key: CART,
      title: t('panels.cart.title'),
    },
    {
      key: CHECKOUT,
      title: t('panels.checkout.title'),
    },
  ] as Panel[],
})
const { cart } = await useVoucherCart({ cinema })

defineOptions({
  name: 'VoucherShop',
})
</script>

<template>
  <div v-if="isInitalised" class="contents">
    <PanelManager>
      <template #navigation="{ step }">
        <PanelNavigation
          :panels
          :active-panel
          :step
          :set-panel
          :set-initial-panel
        />
      </template>
      <template #panels="{ step }">
        <VoucherShopPanelSelect v-if="!step || step === SELECT" :strings>
          <template #header v-if="strings?.headline || strings?.description">
            <VoucherShopHeader
              :headline="strings?.headline ?? ''"
              :description="strings?.description ?? ''"
            />
          </template>
        </VoucherShopPanelSelect>
        <VoucherShopPanelConfigure v-else-if="step === CONFIGURE">
          <template
            #header
            v-if="
              voucherProducts.length === 1 &&
              (strings?.headline || strings?.description)
            "
          >
            <VoucherShopHeader
              :headline="strings?.headline ?? ''"
              :description="strings?.description ?? ''"
            />
          </template>
        </VoucherShopPanelConfigure>
        <VoucherShopPanelCart v-else-if="step === CART && cart" />
        <VoucherShopPanelCheckout v-else-if="step === CHECKOUT && cart" />

        <CartFab
          v-if="(step === SELECT || step === CONFIGURE) && cart"
          @click="setPanel(CART)"
        />
      </template>
    </PanelManager>
  </div>
</template>

<i18n>
de:
  noVouchersAvailable: "Leider sind aktuell keine Gutscheine verfügbar."
  panels:
    configure:
      title: "Konfigurieren"
    cart:
      title: "Warenkorb"
    checkout:
      title: "Kasse"
    select:
      title: "Gutschein-Shop"
es:
  noVouchersAvailable: "Lo sentimos, no hay cupones disponibles."
  panels:
    configure:
      title: "Configurar"
    cart:
      title: "Carrito"
    checkout:
      title: "Pagar"
    select:
      title: "Tienda de regalos"
</i18n>
