import { useIntervalFn } from '@vueuse/core'

interface TimeUnits {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export default function useExpirationTimer({
  expiresAt,
  onExpired,
}: {
  expiresAt: Ref<string | null> | ComputedRef<string | null>
  onExpired?: () => void
}) {
  const now = ref(new Date().getTime())

  const timeUnits = ref<TimeUnits>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  const isFinished = ref(false)

  const timeLeft = computed(() => {
    if (!expiresAt.value) {
      return 0
    }

    const expirationTime = new Date(expiresAt.value).getTime()
    return Math.max(0, expirationTime - now.value)
  })

  function updateCountdown() {
    now.value = new Date().getTime()

    if (!expiresAt.value) {
      return
    }

    const currentTimeLeft = timeLeft.value

    if (currentTimeLeft <= 0) {
      timeUnits.value = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      }
      isFinished.value = true
      pause()
      onExpired?.()
      return
    }

    timeUnits.value = {
      days: Math.floor(currentTimeLeft / (1000 * 60 * 60 * 24)),
      hours: Math.floor(
        (currentTimeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      ),
      minutes: Math.floor((currentTimeLeft % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((currentTimeLeft % (1000 * 60)) / 1000),
    }
  }

  const { pause, resume } = useIntervalFn(updateCountdown, 1000, {
    immediate: false,
  })

  function start() {
    updateCountdown()
    resume()
  }

  function stop() {
    pause()
  }

  function cleanup() {
    stop()
    timeUnits.value = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
    isFinished.value = false
  }

  watch(
    expiresAt,
    (newValue) => {
      if (newValue) {
        start()
      } else {
        cleanup()
      }
    },
    { immediate: true },
  )

  return { timeUnits, isFinished, timeLeft, start, stop, cleanup }
}
