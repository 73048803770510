<script setup lang="ts">
import type { Concession } from '#gql/default'

interface Props {
  concession: Concession
}

const { concession } = defineProps<Props>()

const classes = computed(() => ({
  placeholder: 'w-full h-full',
  image: 'w-full h-auto',
  container: 'aspect-[4/3] overflow-hidden rounded-lg md:rounded-none',
}))

const imageObject = getImage(concession, 'thumbnail', concession.name)
const imageConfig = computed(() => useAppConfig().images.concession.card)

defineOptions({
  name: 'ConcessionImage',
})
</script>

<template>
  <ImageWrapper
    :image="imageObject"
    :config="imageConfig"
    :classes
    :render-above-the-fold="true"
  >
    <template #default="{ classList }">
      <ImagePlaceholder icon="kh:gallery-image" :class="[...classList]" />
    </template>
  </ImageWrapper>
</template>
