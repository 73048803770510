export default function usePanel({
  id,
  scrollIntoView = true,
}: {
  id: string
  scrollIntoView?: boolean
}) {
  onMounted(() => {
    if (id && scrollIntoView) {
      const panel = document.getElementById(id)
      if (panel) {
        panel.scrollIntoView({ behavior: 'instant', block: 'start' })
      }
    }
  })
}
