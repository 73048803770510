<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()
const { page } = useCms()
const { t } = useI18n()

usePageMetas({
  title: `${cinema.name} | ${t('shop')}`,
  description: t('description', { cinemaName: cinema.name }),
  ...cinema.meta,
})

const { $gtm } = useNuxtApp()

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugShop',
})
</script>

<template>
  <PageGridColumn class="widget:pt-0 pb-8">
    <CmsPage v-if="page" :page />
    <GenericPageCinemaConcessionShop :cinema v-else />
  </PageGridColumn>
</template>

<i18n>
de:
  shop: "Shop"
  description: "Jetzt bei kinoheld Merchandise für das Kino {cinemaName} schnell und bequem online kaufen."
</i18n>
