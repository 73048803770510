<template>
  <div>
    <BookingHeading :title="t('payment')" class="mb-2" />
    <FormKit
      :id="inputId"
      type="radio"
      name="selectedPayment"
      :options="paymentMethodOptions"
      :required="true"
      validation="required"
      :tile="true"
      :persist-in-url="true"
      :disabled="disabled"
      :classes="{
        inner: 'items-center',
      }"
      :validation-messages="{
        required: t('validation.required'),
      }"
    >
      <template #label="{ option }">
        <div
          class="flex h-10 w-full cursor-pointer items-center justify-between gap-4"
        >
          <div class="ml-1 text-base" v-text="option.label" />
          <Icon
            v-if="getPaymentIcon(option.value)"
            :key="option.value"
            v-bind="getPaymentIcon(option.value)"
            class="ml-auto flex-shrink-0"
          />
        </div>
      </template>
      <template #suffix="{ option, value }">
        <component
          :is="selectedPaymentComponent"
          v-if="option.value === value && selectedPaymentComponent"
          :payment-method
          :form-id
        />
      </template>
    </FormKit>
  </div>
</template>

<script setup lang="ts">
import type { PaymentMethod } from '#gql/default'
import type { PaymentMethodName } from '@booking/types/payment-method'
import { PAYMENT_METHOD_NAME } from '@booking/constants/payment-method'
import { useFormKitContextById } from '@formkit/vue'

interface Props {
  formId: string
  disabled?: boolean
}

const { formId, disabled = true } = defineProps<Props>()
const { cart } = await useCart()
const { t } = useI18n()
const { getPaymentIcon } = usePaymentMethod()
const inputId = useId()
const context = useFormKitContextById(inputId)

const model = defineModel<PaymentMethodName | ''>()

watch(
  () => context.value?.value,
  (newValue) => {
    if (newValue) {
      model.value = newValue as PaymentMethodName
    }
  }
)

const paymentMethod = computed(() =>
  cart.value?.availablePaymentMethods.find(
    (paymentMethod: PaymentMethod) =>
      paymentMethod.name === context.value?.value
  )
)

interface PaymentMethodOption {
  label: string
  value: PaymentMethodName
}

const paymentMethodOptions = computed<PaymentMethodOption[]>(
  () =>
    cart.value?.availablePaymentMethods.map((paymentMethod: PaymentMethod) => ({
      label: paymentMethod.label,
      value: paymentMethod.name as PaymentMethodName,
    })) ?? []
)

const selectedPaymentComponent = computed(() => {
  if (!context.value?.value) {
    return null
  }

  const paymentComponents: PaymentComponentMap = {
    [PAYMENT_METHOD_NAME.KPS]: () =>
      import('@booking/components/payment-type/kps.vue'),
    [PAYMENT_METHOD_NAME.AMAZON]: () =>
      import('@booking/components/payment-type/amazon.vue'),
    [PAYMENT_METHOD_NAME.PAYPAL]: () =>
      import('@booking/components/payment-type/paypal.vue'),
    [PAYMENT_METHOD_NAME.GOOGLE_PAY]: () =>
      import('@booking/components/payment-type/google-pay.vue'),
  }

  const component = paymentComponents[context.value.value as PaymentMethodName]
  return component ? defineAsyncComponent(component) : null
})

defineOptions({
  name: 'CartPaymentSelection',
})
</script>

<i18n>
de:
  payment: "Zahlung"
  validation:
    required: "Bitte Zahlungsmethode auswählen"
es:
  payment: "Pago"
  validation:
    required: "Por favor, seleccione un método de pago"
</i18n>
