import type { Panel } from '@booking/types/panel-manager'
import type { ConcessionShopStepKey } from '../types/concession-shop'
import {
  CONCESSION_SHOP_QUERY_PARAM,
  CONCESSION_SHOP_PANEL,
} from '@concession-shop/constants/concession-shop'

import { useRouteQuery } from '@vueuse/router'

export default function useConcessionShopPanels() {
  const { STEP } = CONCESSION_SHOP_QUERY_PARAM
  const { SELECT } = CONCESSION_SHOP_PANEL

  const route = useRoute()
  const router = useRouter()
  const step = useRouteQuery<ConcessionShopStepKey | undefined>(STEP)

  const panelsState = useState<{
    panels: Panel[]
    isInitialised: boolean
  }>('concessionShopPanels', () => ({
    panels: [],
    isInitialised: false,
  }))

  function initializePanels(panels: Panel[]) {
    panelsState.value = {
      panels,
      isInitialised: true,
    }

    if (
      !Object.values(CONCESSION_SHOP_PANEL).includes(
        step.value as ConcessionShopStepKey,
      )
    ) {
      setInitialPanel()
    }
  }

  function setInitialPanel() {
    setPanel(SELECT)
  }

  function setPanel(panel?: ConcessionShopStepKey | string) {
    if (
      !panel ||
      !Object.values(CONCESSION_SHOP_PANEL).includes(
        panel as ConcessionShopStepKey,
      )
    ) {
      setInitialPanel()
      return
    }

    const query: Record<string, string | undefined> = {
      ...route.query,
      [STEP]: panel,
    }

    router.push({
      query,
    })
  }

  return {
    step,
    panels: computed<Panel[]>(() => panelsState.value.panels),
    activePanel: computed<Panel | undefined>(() => {
      if (!panelsState.value.isInitialised) {
        return undefined
      }

      const panel = panelsState.value.panels.find(
        (panel) => panel.key === step.value,
      )

      return panel
    }),
    initializePanels,
    setPanel,
    setInitialPanel,
    isInitialised: computed(() => panelsState.value.isInitialised),
  }
}
