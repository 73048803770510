<script setup lang="ts">
import type { CartVoucherProduct } from '#gql/default'

interface Props {
  voucherProduct: CartVoucherProduct
}

const { voucherProduct } = defineProps<Props>()
const { n } = useI18n()

defineOptions({
  name: 'CartOverviewVoucherProduct',
})
</script>

<template>
  <div class="flex justify-between gap-2 py-2">
    <div
      class="truncate"
      v-text="
        formatQuantityAndName(
          voucherProduct.quantity,
          voucherProduct.voucherProduct.name,
        )
      "
    />
    <div v-text="n(voucherProduct.price.total, 'currency')" />
  </div>
</template>
