<script setup lang="ts">
const { t } = useI18n()
const { icons } = useDesign()
const { totalItems } = await useCart()
const emit = defineEmits(['click'])

defineOptions({
  name: 'CartFab',
})
</script>

<template>
  <ButtonFab v-if="totalItems > 0">
    <template #default="{ cssClasses }">
      <button
        :title="t('btn.cart')"
        :class="cssClasses"
        class="bg-neutral hover:bg-neutral-dark-1 text-light flex size-16 items-center justify-center drop-shadow-lg transition-colors duration-200"
        @click="emit('click')"
      >
        <span
          class="bg-primary text-dark absolute right-0 top-0 flex h-5 w-5 items-center justify-center rounded-full text-xs"
          v-text="totalItems"
        />
        <Icon :name="icons.CART_OUTLINE" class="size-6 fill-current" />
      </button>
    </template>
  </ButtonFab>
</template>

<i18n>
de:
  btn:
    cart: "Zum Warenkorb"
es:
  btn:
    cart: "A la canasta de productos"
</i18n>
