import type { ConcessionOrder } from '#gql/default'

export default function useUserOrders() {
  const orders = useState<ConcessionOrder[]>('user-orders', () => [])

  const { isLoggedIn } = useUserAuth()

  async function fetchUserOrders() {
    if (!isLoggedIn.value) {
      return
    }

    try {
      const response = await GqlFetchUserWithOrdersForOrderLandingPage()

      if (response.me?.orders?.data && response.me.orders.data.length > 0) {
        orders.value.push(...response.me.orders.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    orders,
    fetchUserOrders,
  }
}
