<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()
const { shopData, search } = await useConcessionShop()
const isHeaderVisible = computed(
  () => (shopData?.value?.categories?.length ?? 0) > 1 && !search.value,
)

defineOptions({
  name: 'ConcessionCards',
})
</script>

<template>
  <div
    class="widget:group-data-[default-horizontal-spacing=true]:container-spacing-horizontal group-data-[default-horizontal-spacing=true]:container-bleed md:group-data-[default-horizontal-spacing=true]:container-spacing-horizontal lg:grid lg:grid-cols-2 lg:gap-4"
  >
    <template v-for="category in shopData?.categories" :key="category.id">
      <ConcessionCategoryHeader
        v-if="isHeaderVisible"
        :category
        class="col-span-full mb-6 mt-8 first:mt-0"
      />

      <ConcessionCard
        v-for="concession in category.concessions"
        :key="concession.id"
        :concession
        :cinema
        class="md:mb-4 lg:mb-0"
      />

      <template
        v-for="subCategory in category.subCategories"
        :key="subCategory.id"
      >
        <ConcessionCategoryHeader
          v-if="isHeaderVisible"
          :category="subCategory"
          class="col-span-full mb-6 mt-8 first:mt-0"
        />

        <ConcessionCard
          v-for="concession in subCategory.concessions"
          :key="concession.id"
          :concession
          :cinema
          class="md:mb-4 lg:mb-0"
        />
      </template>
    </template>
    <ConcessionCard
      v-for="concession in shopData?.concessions"
      :key="concession.id"
      :concession
      :cinema
      class="md:mb-4 lg:mb-0"
    />
  </div>
</template>
