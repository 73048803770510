<script setup lang="ts">
import type { ConcessionOrder } from '#gql/default'

interface Props {
  order: ConcessionOrder
}

const { order } = defineProps<Props>()

const { startPreparation } = useOrderConcessions()
const { icons } = useDesign()
const { ct } = useCinemaStrings()

const { STATUS_PREORDERED, START_PREPARATION } =
  ORDER_CONCESSIONS_CINEMA_STRINGS

defineOptions({
  name: 'OnSiteConcessionsCardStatusPreordered',
})
</script>

<template>
  <div>
    <div class="grid grid-cols-[1fr,auto] gap-2">
      <Icon :name="icons.CART_CHECK" class="size-8" />
      <span v-text="ct(STATUS_PREORDERED)" />
    </div>

    <Button
      :text="ct(START_PREPARATION)"
      @click="startPreparation(order.id, order.cinema.id)"
    />
  </div>
</template>
