<script setup lang="ts">
import type { Concession } from '#gql/default'

const { t } = useI18n()
const { icons } = useDesign()

interface Props {
  quantity: number
  concession: Concession
}

const { quantity, concession } = defineProps<Props>()
const hasSubitemSelection = computed(() =>
  concession.subItems?.some((item) => (item?.options?.length ?? 0) > 0),
)

defineOptions({
  name: 'ConcessionConfigureButtonQuickAdd',
})
</script>

<template>
  <button
    type="button"
    :title="
      hasSubitemSelection
        ? t('btn.configure', { concession: concession.name })
        : t('btn.add', { concession: concession.name })
    "
    class="absolute right-1.5 top-1.5 flex size-10 items-center justify-center lg:right-2 lg:top-2"
  >
    <div
      class="dark:bg-neutral-dark-2/80 bg:neutral-light-2/80 dark:hover:bg-neutral-dark-2 hover:bg:neutral-light-2 border-neutral-dark-1 dark:border-neutral-light-1 dark:text-light text-dark flex size-7 items-center justify-center rounded-full border shadow-lg lg:size-8"
    >
      <span v-if="quantity" v-text="quantity?.toString()" class="text-sm" />
      <Icon v-if="!quantity" :name="icons.PLUS" class="size-5" />
    </div>
  </button>
</template>

<i18n>
de:
  btn:
    configure: "{concession} konfigurieren"
    add: "{concession} hinzufügen"
en:
  btn:
    configure: "Configure {concession}"
    add: "Add {concession}"
es:
  btn:
    configure: "Agregar {concession}"
    add: "Agregar {concession}"
</i18n>
