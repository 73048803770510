<script setup lang="ts">
import type { VoucherProductCustomisation } from '#gql/default'
import { BUTTON_THEME } from '@kh-ui/constants/button'

interface Props {
  voucherProductId: string
  customisations: VoucherProductCustomisation[]
  selectedAmount: number
  voucherDesignId: string
  quantity: number
  disabled?: boolean
}

const props = defineProps<Props>()
const { t } = useI18n()

const isModalOpen = ref(false)
const isDisabled = computed(
  () => !props.selectedAmount || !props.voucherDesignId || props.disabled,
)

defineOptions({
  name: 'VoucherProductPreview',
})
</script>

<template>
  <div>
    <KButton
      :text="t('btn.preview')"
      :theme="BUTTON_THEME.SECONDARY"
      class="w-full"
      :disabled="isDisabled"
      @click="isModalOpen = true"
    />

    <LazyVoucherProductPreviewModal
      v-if="isModalOpen && !isDisabled"
      :voucher-product-id
      :customisations
      :selected-amount
      :voucher-design-id
      :quantity
      @close="isModalOpen = false"
    />
  </div>
</template>

<i18n>
de:
  btn:
    preview: "PDF Vorschau anzeigen"
en:
  btn:
    preview: "Preview PDF"
es:
  btn:
    preview: "Ver PDF de vista previa"
</i18n>
