<script setup lang="ts">
import type { VoucherProductCustomisation } from '#gql/default'

interface Props {
  customisation?: VoucherProductCustomisation
  title: string
  disabled?: boolean
}

const { title, customisation = {} } = defineProps<Props>()
const { t } = useI18n()
const { icons } = useDesign()
const emit = defineEmits(['close', 'update'])

const isModalOpen = ref(false)

const formData = ref({
  text: customisation?.text || '',
})

const description = computed(() =>
  formData.value.text && formData.value.text !== '<p></p>'
    ? formData.value.text
    : `${t('btn.personalisation.description')} ${t('optional')}`,
)

function handleSubmit() {
  emit('update', {
    text:
      formData.value.text && formData.value.text !== '<p></p>'
        ? formData.value.text
        : '',
  })
  close()
}

const { close, cssClasses, open } = useModal({
  onClose: () => {
    emit('close')
    isModalOpen.value = false
  },
})

function openModal() {
  isModalOpen.value = true
  open()
}

defineOptions({
  name: 'VoucherProductFormConfigureCustomisation',
})
</script>

<template>
  <div>
    <BookingButton
      :label="t(`btn.personalisation.label`)"
      :description
      :prefix-icon="icons.EDIT"
      :suffix-icon="icons.CHEVRON_RIGHT"
      :disabled="disabled"
      @click="openModal"
    />

    <Teleport v-if="isModalOpen" to="body">
      <div :class="cssClasses.container">
        <div :class="cssClasses.modal" class="relative space-y-4 sm:!max-w-xl">
          <div class="flex items-center justify-between">
            <div class="text-lg" v-text="title" />
            <ButtonClose :class="cssClasses.close" @click="close()" />
          </div>

          <div class="space-y-4 py-4">
            <FormKit
              type="wysiwyg"
              maxlength="200"
              v-model="formData.text"
              :char-count="true"
              validation="length:0,200"
              :editor-config="{
                extensions: ['text'],
              }"
              :classes="{
                input: 'max-h-[10lh]',
              }"
              name="text"
            />

            <div class="pt-6">
              <KButton
                :theme="BUTTON_THEME.PRIMARY"
                :type="BUTTON_TYPE.SUBMIT"
                :text="t('btn.save')"
                class="w-full"
                @click="handleSubmit"
              />
            </div>
          </div>
        </div>
        <div :class="cssClasses.backdrop" @click="close" />
      </div>
    </Teleport>
  </div>
</template>

<i18n>
de:
  optional: "(optional)"
  btn:
    save: "Speichern und fortfahren"
    personalisation:
      label: "Personalisierung"
      description: "Persönliche Nachricht hinzufügen"
  label:
    text: "Persönliche Nachricht"
es:
  optional: "(opcional)"
  btn:
    save: "Guardar y continuar"
    personalisation:
      label: "Personalización"
      description: "Agregar mensaje personalizado"
  label:
    text: "Mensaje personal"
</i18n>
