import formatQuantityAndName from './format-quantity-and-name'

export default function formatSubItemSelection(
  subItems: Array<{ option?: { name?: string | null } | null }>,
) {
  if (!subItems?.length) return ''

  const items = subItems
    .map(({ option }) => option?.name)
    .filter((name): name is string => Boolean(name))

  const counts = items.reduce(
    (acc, name) => {
      acc[name] = (acc[name] || 0) + 1
      return acc
    },
    {} as Record<string, number>,
  )

  return Object.entries(counts)
    .map(([name, count]) =>
      count > 1 ? formatQuantityAndName(count, name) : name,
    )
    .join(', ')
}
