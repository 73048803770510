<script setup lang="ts">
import type { CartConcession, Concession } from '#gql/default'

interface Props {
  concession: Concession
  selectedConcessions?: CartConcession[]
  quantity?: number
}

const { concession, selectedConcessions = [], quantity } = defineProps<Props>()
const { t, n } = useI18n()

const emit = defineEmits(['update'])

const formData = ref({
  quantity: selectedConcessions?.length > 1 ? undefined : quantity,
  configurations: selectedConcessions?.map((selectedConcession) => {
    const config: Record<string, string | undefined> = {}
    selectedConcession?.subItemSelection?.forEach((selection) => {
      if (selection?.subItem?.id) {
        config[selection.subItem.id] = selection.option?.id ?? ''
      }
    })
    if (selectedConcessions.length > 1) {
      config.quantity = selectedConcession.quantity.toString()
    }
    return config
  }) || [{}],
})

const submitButtonLabel = computed(() => {
  if (selectedConcessions.length === 1) {
    return Number(formData.value.quantity) >= 1
      ? t('btn.update')
      : t('btn.remove')
  } else if (selectedConcessions.length > 1) {
    return t('btn.update')
  }

  return t('btn.add', {
    price: n(
      (formData.value.quantity ?? 1) * concession.price.total,
      'currency',
    ),
  })
})

async function handleSubmit(data: Record<string, any>) {
  const payload: {
    quantity: number
    subItemSelection: { optionId?: string; subItemId: string }[]
  }[] = []

  if (data.configurations.length === 1) {
    payload.push({
      quantity: Number(data.quantity),
      subItemSelection: Object.keys(data.configurations[0]).map((key) => ({
        subItemId: key,
        optionId: data.configurations[0][key]
          ? data.configurations[0][key]
          : undefined,
      })),
    })
  } else {
    data.configurations.forEach((config: any) => {
      payload.push({
        quantity: Number(config.quantity),
        subItemSelection: Object.keys(config)
          .filter((key) => key !== 'quantity')
          .map((key) => ({
            subItemId: key,
            optionId: config[key] ? config[key] : undefined,
          })),
      })
    })
  }

  emit('update', payload)
}
</script>

<template>
  <FormKit
    type="form"
    v-model="formData"
    :actions="false"
    @submit="handleSubmit"
  >
    <div class="scrollbar-themed max-h-[60vh] space-y-2 overflow-y-auto">
      <FormKit
        type="repeater"
        name="configurations"
        :add-button="false"
        :up-control="false"
        :down-control="false"
        :insert-control="false"
        :remove-control="false"
        :classes="{
          items: 'space-y-4',
          content: 'space-y-4',
        }"
      >
        <ConcessionConfigureSubItem
          v-for="subItem in concession.subItems"
          :key="subItem?.id"
          :sub-item="subItem!"
        />

        <FormKit
          v-if="selectedConcessions.length > 1"
          type="number"
          name="quantity"
          :min="0"
          :max="Number(concession.selectionMax) || undefined"
          :step="1"
        />
      </FormKit>
    </div>

    <div class="flex gap-4 pb-4 pt-8">
      <FormKit
        v-if="selectedConcessions.length <= 1"
        type="number"
        name="quantity"
        :min="0"
        :max="Number(concession.selectionMax) || undefined"
        :step="1"
      />
      <KButton
        :type="BUTTON_TYPE.SUBMIT"
        :theme="BUTTON_THEME.PRIMARY"
        :text="submitButtonLabel"
        class="w-full"
      />
    </div>
  </FormKit>
</template>

<i18n>
en:
  select: "Select"
  btn:
    add: "Add ({price})"
    update: "Update"
    remove: "Remove"
de:
  select: "Wählen"
  btn:
    add: "Hinzufügen ({price})"
    update: "Aktualisieren"
    remove: "Entfernen"
es:
  select: "Seleccionar"
  btn:
    add: "Añadir ({price})"
    update: "Actualizar"
    remove: "Eliminar"
</i18n>
