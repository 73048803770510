<script setup lang="ts">
import type { CartConcession } from '#gql/default'

interface Props {
  cartConcession: CartConcession
}

const props = defineProps<Props>()
const { n } = useI18n()
const { cinema } = await useConcessionShop()
const { addToCart, pending } = await useConcessionCart({
  cinema: cinema.value!,
})

const emit = defineEmits<{
  (e: 'edit'): void
}>()

async function handleUpdate({ quantity }: { quantity: number }) {
  if (pending.value) {
    return
  }

  addToCart({
    concession: props.cartConcession.concession,
    items: [
      {
        quantity,
        concessionId: props.cartConcession.concession.id,
        subItemSelection: props.cartConcession.subItemSelection?.map(
          ({ option, subItem }) => ({
            optionId: option?.id,
            subItemId: subItem.id,
          }),
        ),
      },
    ],
  })
}

function handleEdit() {
  emit('edit')
}

defineOptions({
  name: 'CartConcession',
})
</script>

<template>
  <div
    data-cart-item-concession
    class="grid gap-y-4 py-4 transition-opacity duration-300"
  >
    <div class="grid grid-cols-[auto,1fr] items-center gap-x-6">
      <div
        class="font-bold"
        v-text="
          formatQuantityAndName(
            cartConcession.quantity,
            cartConcession.concession.name,
          )
        "
      />
      <div
        class="text-right font-bold"
        v-text="n(cartConcession.price.total, 'currency')"
      />
    </div>

    <div
      class="text-sm empty:hidden"
      v-text="formatSubItemSelection(cartConcession?.subItemSelection || [])"
    />

    <CartFormUpdateItem
      :quantity="cartConcession.quantity"
      class="mt-2"
      @update="handleUpdate"
      @edit="handleEdit"
    />
  </div>
</template>
