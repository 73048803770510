<script setup lang="ts">
interface Props {
  expiresAt: string
}

const { expiresAt } = defineProps<Props>()
const { t } = useI18n()
const { icons } = useDesign()

const { timeUnits, timeLeft } = useExpirationTimer({
  expiresAt: computed(() => expiresAt),
})

function formattedTime() {
  if (timeLeft.value === 0) {
    return '00:00:00'
  }
  const { hours, minutes, seconds } = timeUnits.value
  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0'),
  ].join(':')
}

const cssClasses = computed(() => ({
  'border-system-success bg-system-success/80 dark:bg-system-success/20 text-on-system-success dark:text-light':
    timeLeft.value > 140,
  'border-system-info bg-system-info/80 dark:bg-system-info/20 text-on-system-info dark:text-light':
    timeLeft.value < 140 && timeLeft.value > 40,
  'border-system-error bg-system-error/80 dark:bg-system-error/20 text-on-system-error dark:text-light':
    timeLeft.value <= 40,
}))

defineOptions({
  name: 'Countdown',
})
</script>

<template>
  <div
    class="flex select-none items-center gap-2 overflow-hidden rounded-md border p-2 transition-colors duration-300"
    :class="cssClasses"
  >
    <div class="relative z-20 flex items-center gap-2 text-sm">
      <Icon :name="icons.COUNTDOWN" class="size-6 fill-current" />
      <span v-text="t('reservationTime')" />
      <span class="tabular-nums" v-text="formattedTime()" />
    </div>
  </div>
</template>

<i18n>
de:
  reservationTime: "Reservierungszeit:"
es:
  reservationTime: "Tiempo de reserva:"
en:
  reservationTime: "Reservation time:"
</i18n>
