import type {
  CartVoucherProductInput,
  Cart,
  CartType,
  Cinema,
  VoucherProduct,
  CartVoucherProduct,
} from '#gql/default'
import { CART_TYPE } from '@booking/constants/cart'

export default async function useVoucherCart({ cinema }: { cinema?: Cinema }) {
  const { add: addMessage } = useMessage()
  const {
    cart,
    fetchCart,
    ensureCart,
    ensureCartStrings,
    pending,
    getMessage,
    getCartItem,
  } = await useCart()

  await ensureCartStrings({ cinema })

  async function getCartVoucher(cartVoucherId: string) {
    return cart.value?.voucherProducts.find(
      ({ id }: { id: string }) => id === cartVoucherId,
    )
  }

  async function patchVoucherProducts(
    voucherProducts: CartVoucherProductInput[],
  ): Promise<Cart> {
    pending.value = true
    try {
      let cartId = cart.value?.id

      if (!cartId) {
        const { id } = await ensureCart({
          cinema,
          type: CART_TYPE.VOUCHER as CartType,
        })
        cartId = id
      }

      const result = await GqlCartPatchVoucherProducts({
        cartId,
        voucherProducts,
      })

      const updatedCart = result.cartPatchVoucherProducts as Cart
      cart.value = updatedCart

      return updatedCart
    } catch (error) {
      throw new Error(error as string)
    } finally {
      pending.value = false
    }
  }

  async function addToCart(
    payload: CartVoucherProductInput & {
      voucherProduct: VoucherProduct
      cartVoucherProduct?: CartVoucherProduct
      onSuccess?: () => void
    },
  ) {
    const {
      quantity,
      selectedAmount,
      voucherDesignId,
      voucherProduct,
      cartVoucherProduct,
      customisations,
      locale,
      onSuccess,
    } = payload

    try {
      await patchVoucherProducts([
        {
          id: cartVoucherProduct?.id,
          voucherProductId: voucherProduct.id,
          customisations: customisations
            ?.slice(0, quantity)
            .map((customisation) => ({
              ...Object.fromEntries(
                Object.entries(customisation).filter(([_, v]) => v != null),
              ),
            })),
          voucherDesignId,
          locale,
          quantity,
          selectedAmount,
        },
      ])

      addMessage({
        message: getMessage({
          newQuantity: quantity,
          previousQuantity: cartVoucherProduct?.quantity,
          cartItemId: cartVoucherProduct?.id,
          productName: voucherProduct.name,
        }),
        severity: MESSAGE_SEVERITY.SUCCESS,
        type: MESSAGE_TYPE.TOAST,
      })
      onSuccess?.()
    } catch {
      addMessage({
        message: getMessage(),
        severity: MESSAGE_SEVERITY.ERROR,
        type: MESSAGE_TYPE.TOAST,
      })
    }
  }

  return {
    cart,
    fetchCart,
    patchVoucherProducts,
    getCartVoucher,
    getCartItem,
    addToCart,
    pending,
  }
}
