<script setup lang="ts">
import type { ConcessionOrder } from '#gql/default'

interface Props {
  order: ConcessionOrder
}

const { order } = defineProps<Props>()

const { fetchOrderByPickupCode, ORDER_CONCESSIONS_CARD_STATUS } =
  useOrderConcessions()

const { PREORDERED, PREPARE, IN_PREPARATION, READY, COMPLETE, EXPIRED } =
  ORDER_CONCESSIONS_CARD_STATUS

const timeoutRef = ref<NodeJS.Timeout | null>(null)
const concessionStatus = computed(() => order.concessionStatus)

watch(concessionStatus, () => {
  if (concessionStatus.value === EXPIRED && timeoutRef.value) {
    clearTimeout(timeoutRef.value)
    timeoutRef.value = null
  }
})

onMounted(() => {
  timeoutRef.value = setTimeout(() => {
    fetchOrderByPickupCode(order.pickupCode, order.cinema.id)
  }, 45000)
})

onUnmounted(() => {
  if (timeoutRef.value) {
    clearTimeout(timeoutRef.value)
  }
})

defineOptions({
  name: 'OnSiteConcessionsCardStatus',
})
</script>

<template>
  <div
    class="[&>div]:bg-neutral-light-2 dark:[&>div]:bg-neutral-dark-2 contents [&>div]:flex [&>div]:flex-col [&>div]:gap-4 [&>div]:rounded-lg [&>div]:border [&>div]:p-4"
  >
    <OnSiteConcessionsCardStatusPreordered
      v-if="concessionStatus === PREORDERED || concessionStatus === null"
      :order
    />
    <OnSiteConcessionsCardStatusPrepare
      v-else-if="concessionStatus === PREPARE"
      class="bg-system-info text-on-system-info flex-row items-center"
      :order
    />
    <OnSiteConcessionsCardStatusInPreparation
      v-else-if="concessionStatus === IN_PREPARATION"
      class="bg-system-info text-on-system-info flex-row items-center"
      :order
    />
    <OnSiteConcessionsCardStatusReady
      v-else-if="concessionStatus === READY"
      class="bg-system-success text-on-success flex-row items-center"
      :order
    />
    <OnSiteConcessionsCardStatusComplete
      class="flex-row items-center"
      v-else-if="concessionStatus === COMPLETE"
    />
    <OnSiteConcessionsCardStatusExpired
      class="flex-row items-center"
      v-else-if="concessionStatus === EXPIRED"
    />
  </div>
</template>
