e
<script setup lang="ts">
const { icons } = useDesign()
const { ct } = useCinemaStrings()

const { STATUS_COMPLETE } = ORDER_CONCESSIONS_CINEMA_STRINGS

defineOptions({
  name: 'OnSiteConcessionsCardStatusComplete',
})
</script>

<template>
  <div>
    <Icon :name="icons.CHECK" class="size-8" />
    <span v-text="ct(STATUS_COMPLETE)" />
  </div>
</template>
