<script setup lang="ts">
interface Props {
  availablePaymentMethods: any[]
}

const { availablePaymentMethods } = defineProps<Props>()
const { t } = useI18n()
const { getPaymentIcon } = usePaymentMethod()

defineOptions({
  name: 'CartAvailablePaymentMethods',
})
</script>

<template>
  <div class="w-full">
    <p class="mb-4" v-text="t('payEasyAndSecure')" />

    <div class="flex flex-wrap">
      <component
        v-for="{ name } in availablePaymentMethods"
        :key="name"
        :is="getPaymentIcon(name).name"
        :class="['mb-2 mr-4', getPaymentIcon(name).class]"
      />
    </div>
  </div>
</template>

<i18n>
de:
  payEasyAndSecure: "Einfach und sicher bezahlen, mit"
en:
  payEasyAndSecure: "Pay easily and securely, with"
es:
  payEasyAndSecure: "Paga fácil y seguro, con"
</i18n>
