<script setup lang="ts">
const { t } = useI18n()

const { cinema, voucherId, cartVoucherId, voucherProducts, getVoucherProduct } =
  await useVoucherShop()
const { getCartItem } = await useVoucherCart({ cinema: cinema.value })

const cartVoucherProduct = computed(() => {
  if (cartVoucherId.value) {
    return getCartItem({ id: cartVoucherId.value })
  }

  return undefined
})

const voucherProduct = computed(() => {
  if (cartVoucherProduct.value) {
    return cartVoucherProduct.value.voucherProduct
  }

  if (voucherId.value) {
    return getVoucherProduct(voucherId.value)
  }

  return undefined
})

const panelId = useId()
usePanel({
  id: panelId,
  scrollIntoView: voucherProducts.value?.length > 1,
})

defineOptions({
  name: 'VoucherShopPanelConfigure',
})
</script>

<template>
  <Panel v-if="voucherProduct" :id="panelId" data-panel-configure>
    <template #header>
      <slot name="header" />
    </template>

    <template #default="{ cssClasses }">
      <div :class="cssClasses.grid">
        <div :class="[cssClasses.content, 'order-2 md:order-1']">
          <VoucherProductFormConfigure :voucher-product :cart-voucher-product />
        </div>

        <div :class="[cssClasses.sidebar, 'order-1 md:order-2']">
          <VoucherProductImage
            :voucher-product
            :render-above-the-fold="true"
            class="hidden md:block"
          />
          <h3 class="text-lg font-bold" v-text="t('terms')" />
          <div
            class="prose dark:prose-invert prose-li:list-disc dark:prose-li:marker:text-light prose-li:marker:text-dark prose-li:my-1 prose-li:leading-tight"
            v-html="voucherProduct.terms"
          />
        </div>
      </div>
    </template>
  </Panel>
</template>

<i18n>
de:
  terms: "Konditionen"
en:
  terms: "Terms"
es:
  terms: "Términos"
</i18n>
