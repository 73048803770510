<template>
  <div>
    <FormKit
      type="checkbox"
      name="termsOfService"
      validation="accepted"
      :validation-label="t('termsOfServiceLabel')"
    >
      <template #label>
        <div>
          <span
            v-html="
              t('termsOfService', {
                termsLink: getTermsLink(),
                privacyLink: getPrivacyLink(),
              })
            "
          />
          <small
            class="text-neutral-dark-2 dark:text-neutral-light-2 mt-1 block text-xs"
            v-html="
              t('help', {
                securityLink: getSecurityLink(),
              })
            "
          />
        </div>
      </template>
    </FormKit>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

function getTermsLink() {
  return `<a href='/agb' class='underline hover:no-underline' target='_blank'>${t('terms')}</a>`
}

function getPrivacyLink() {
  return `<a href='/datenschutz' class='underline hover:no-underline' target='_blank'>${t('privacy')}</a>`
}

function getSecurityLink() {
  return `<a href='/sicherheit' class='underline hover:no-underline' target='_blank'>${t('security')}</a>`
}

defineOptions({
  name: 'CartTermsOfService',
})
</script>

<i18n>
de:
  termsOfService: "Hiermit akzeptiere ich die {termsLink} und {privacyLink} von kinoheld."
  help: "Hinweis: Dieser Onlineshop wird von der kinoheld GmbH betrieben. Alle Daten werden sicher verschlüsselt via TLS übermittelt. Siehe {securityLink}."
  terms: "AGB"
  privacy: "Datenschutzbestimmungen"
  security: "Sicherheit"
  termsOfServiceLabel: "Datenschutzbestimmungen"
es:
  termsOfService: "Acepto los {termsLink} y la {privacyLink} de kinoheld."
  help: "Nota: Este sitio web se opera por kinoheld GmbH. Todos los datos se envían de forma segura a través de TLS. Ver {securityLink}."
  terms: "términos y condiciones de uso"
  privacy: "política de privacidad"
  security: "seguridad"
  termsOfServiceLabel: "política de privacidad"
</i18n>
