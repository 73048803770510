<script setup lang="ts">
import type { ResponsiveBehaviour } from '@base/composables/use-horizontal-media-scroller'
import { BUTTON_SIZE, BUTTON_THEME } from '@kh-ui/constants/button'
import { TAILWIND_CONTAINER_BREAKPOINT } from '@theme/constants/tailwind'
import { useActiveElement, usePointer } from '@vueuse/core'

export interface Props {
  slides: any[]
  imageSelector?: string
  hideInactiveButtons?: boolean
  edgeFadeEffect?: boolean
  responsiveBehaviour?: ResponsiveBehaviour
  navButtons?: {
    theme?: ButtonThemes
    size?: ButtonSizes
    cssClasses?: {
      prev?: string | object | []
      next?: string | object | []
    }
  }
}

const props = withDefaults(defineProps<Props>(), {
  imageSelector: '[data-image]',
  hideInactiveButtons: true,
  edgeFadeEffect: true,
  responsiveBehaviour: () => ({
    [TAILWIND_CONTAINER_BREAKPOINT.BREAKPOINT_XS]: {
      slidesPerPage: 2,
      gapSize: 8,
    },
    [TAILWIND_CONTAINER_BREAKPOINT.BREAKPOINT_SM]: {
      slidesPerPage: 3,
      gapSize: 8,
    },
    [TAILWIND_CONTAINER_BREAKPOINT.BREAKPOINT_MD]: {
      slidesPerPage: 3,
      gapSize: 16,
    },
    [TAILWIND_CONTAINER_BREAKPOINT.BREAKPOINT_LG]: {
      slidesPerPage: 4,
      gapSize: 16,
    },
    [TAILWIND_CONTAINER_BREAKPOINT.BREAKPOINT_2XL]: {
      slidesPerPage: 4,
      gapSize: 16,
    },
    [TAILWIND_CONTAINER_BREAKPOINT.BREAKPOINT_3XL]: {
      slidesPerPage: 5,
      gapSize: 16,
    },
  }),
  navButtons: () => ({
    theme: BUTTON_THEME.PRIMARY,
    size: BUTTON_SIZE.SM,
    cssClasses: {
      prev: 'absolute z-20 -translate-y-1/2 left-3 md:left-3 xl:left-5 data-[is-active=false]:opacity-25 data-[is-active=false]:pointer-events-none',
      next: 'absolute z-20 -translate-y-1/2 right-3 md:right-3 xl:right-5 data-[is-active=false]:opacity-25 data-[is-active=false]:pointer-events-none',
    },
  }),
})

const activeElement = useActiveElement()
const currentSlide = ref(0)

const containerRef = ref<HTMLElement>()
const wrapperRef = ref<HTMLElement>()

const slidesRef = ref(props.slides)

// Update slidesRef when props.slides changes
watch(
  () => props.slides,
  (newSlides) => {
    slidesRef.value = newSlides
  },
  { immediate: true },
)

const {
  position,
  isDragging,
  isAnimating,
  onTouchStart,
  slideLeft,
  slideRight,
  onTouchEnd,
  onTouchMove,
  canSlideLeft,
  canSlideRight,
  cssVariables,
  currentBreakpoint,
} = useHorizontalMediaScroller({
  slides: slidesRef,
  responsiveBehaviour: props.responsiveBehaviour,
  wrapperRef,
})

const { icons } = useDesign()
const slideRef = ref()
const imageHeight = ref(0)
const imageElement = ref<HTMLElement>()

// Watch for slideRef changes and get image element
watch(
  () => slideRef.value?.[0],
  (slideContainer) => {
    if (slideContainer) {
      imageElement.value = slideContainer.querySelector(props.imageSelector)
    }
  },
  { immediate: true, flush: 'post' },
)

// Use element size for accurate height tracking
const { height: measuredHeight } = useElementSize(imageElement)
watch(measuredHeight, (newHeight) => {
  if (newHeight > 0) {
    imageHeight.value = newHeight
  }
})

onMounted(() => {
  // Initial check in case images are already loaded
  if (imageElement.value?.clientHeight) {
    imageHeight.value = imageElement.value.clientHeight
  }
})

const { pointerType } = usePointer()

defineOptions({
  name: 'HorizontalMediaScroller',
})
</script>

<template>
  <div
    ref="containerRef"
    :data-horizontal-media-scroller="currentBreakpoint"
    :class="[
      'group/scroller @container/scroller group-data-[default-horizontal-spacing=true]:container-spacing-horizontal relative overflow-hidden',
      { 'edge-fade-effect': edgeFadeEffect },
    ]"
    :style="cssVariables"
  >
    <div class="h-full w-full">
      <div ref="wrapperRef" class="w-full md:overflow-hidden">
        <ClientOnly>
          <KButton
            v-if="!hideInactiveButtons || canSlideLeft"
            :data-is-active="canSlideLeft"
            :icon="icons.CHEVRON_LEFT"
            :theme="navButtons?.theme"
            :size="navButtons?.size"
            :class="[
              navButtons?.cssClasses?.prev,
              pointerType === 'mouse' ? '!flex' : 'hidden md:flex',
            ]"
            :style="`top: ${imageHeight / 2}px`"
            @click="slideLeft(), activeElement?.blur()"
          />
        </ClientOnly>

        <div
          v-if="slides.length"
          data-horizontal-media-scroller-grid
          :data-can-slide-left="canSlideLeft"
          :data-can-slide-right="canSlideRight"
          class="horizontal-media-scroller-grid"
          :style="{
            transform: `translateX(${position}px)`,
          }"
          :class="{
            'cubic-bezier(0.1, 0, 0.1, 1) transition-transform duration-[400ms]':
              !isDragging && isAnimating,
            'transition-none': isDragging,
          }"
          @touchstart.passive="onTouchStart($event)"
          @touchmove.passive="onTouchMove"
          @touchend.passive="onTouchEnd"
        >
          <div
            v-for="(slide, index) in slides"
            :key="index"
            data-horizontal-media-scroller-slide
            class="horizontal-media-scroller-slide"
          >
            <div ref="slideRef" class="contents">
              <slot
                :slide="slide"
                :current-slide="currentSlide"
                :index="index"
              />
            </div>
          </div>
        </div>

        <ClientOnly>
          <KButton
            v-if="!hideInactiveButtons || canSlideRight"
            :data-is-active="canSlideRight"
            :icon="icons.CHEVRON_RIGHT"
            :theme="navButtons?.theme"
            :size="navButtons?.size"
            :class="[
              navButtons?.cssClasses?.next,
              pointerType === 'mouse' ? '!flex' : 'hidden md:flex',
            ]"
            :style="`top: ${imageHeight / 2}px`"
            @click="slideRight(), activeElement?.blur()"
          />
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
[data-horizontal-media-scroller] {
  @apply relative;

  &.edge-fade-effect {
    &::before,
    &::after {
      @apply from-body via-body/50 pointer-events-none absolute top-0 z-10 h-full to-transparent opacity-0 transition-opacity duration-500 ease-in-out md:hidden;
      content: '';
      width: calc(var(--container-padding) + var(--slide-gap));
    }

    &::before {
      @apply left-0 bg-gradient-to-r;
    }

    &::after {
      @apply right-0 bg-gradient-to-l;
    }

    &:has([data-can-slide-left='true'])::before {
      @apply opacity-60;
    }

    &:has([data-can-slide-right='true'])::after {
      @apply opacity-60;
    }

    @screen sm {
      &::before,
      &::after {
        width: calc(var(--container-padding-sm) + var(--slide-gap-sm));
      }
    }
  }
}

.horizontal-media-scroller-grid {
  @apply relative grid grid-flow-col;

  /* Default sizing */
  @apply w-[var(--grid-width)] auto-cols-[var(--slide-width)] gap-x-[var(--slide-gap)];

  /* Breakpoint-specific styles */
  @apply @xs/scroller:auto-cols-[var(--slide-width-xs)] @xs/scroller:gap-x-[var(--slide-gap-xs)] @xs/scroller:w-[var(--grid-width-xs)];
  @apply @sm/scroller:auto-cols-[var(--slide-width-sm)] @sm/scroller:gap-x-[var(--slide-gap-sm)] @sm/scroller:w-[var(--grid-width-sm)];
  @apply @md/scroller:auto-cols-[var(--slide-width-md)] @md/scroller:gap-x-[var(--slide-gap-md)] @md/scroller:w-[var(--grid-width-md)];
  @apply @lg/scroller:auto-cols-[var(--slide-width-lg)] @lg/scroller:gap-x-[var(--slide-gap-lg)] @lg/scroller:w-[var(--grid-width-lg)];
  @apply @xl/scroller:auto-cols-[var(--slide-width-xl)] @xl/scroller:gap-x-[var(--slide-gap-xl)] @xl/scroller:w-[var(--grid-width-xl)];
  @apply @2xl/scroller:auto-cols-[var(--slide-width-2xl)] @2xl/scroller:gap-x-[var(--slide-gap-2xl)] @2xl/scroller:w-[var(--grid-width-2xl)];
  @apply @3xl/scroller:auto-cols-[var(--slide-width-3xl)] @3xl/scroller:gap-x-[var(--slide-gap-3xl)] @3xl/scroller:w-[var(--grid-width-3xl)];
  @apply @4xl/scroller:auto-cols-[var(--slide-width-4xl)] @4xl/scroller:gap-x-[var(--slide-gap-4xl)] @4xl/scroller:w-[var(--grid-width-4xl)];
  @apply @5xl/scroller:auto-cols-[var(--slide-width-5xl)] @5xl/scroller:gap-x-[var(--slide-gap-5xl)] @5xl/scroller:w-[var(--grid-width-5xl)];
  @apply @6xl/scroller:auto-cols-[var(--slide-width-6xl)] @6xl/scroller:gap-x-[var(--slide-gap-6xl)] @6xl/scroller:w-[var(--grid-width-6xl)];
  @apply @7xl/scroller:auto-cols-[var(--slide-width-7xl)] @7xl/scroller:gap-x-[var(--slide-gap-7xl)] @7xl/scroller:w-[var(--grid-width-7xl)];
}

.horizontal-media-scroller-slide {
  @apply flex shrink-0 grow-0 flex-col overflow-hidden;
}
</style>
