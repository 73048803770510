import type {
  Cinema,
  Concession,
  ConcessionCategory,
  ConcessionMainCategory,
  ConcessionShop,
} from '#gql/default'
import { useRouteQuery } from '@vueuse/router'

export default async function useConcessionShop({
  type,
  cinema,
}: { type?: 'SHOPITEM'; cinema?: Cinema } = {}) {
  const route = useRoute()
  const search = useRouteQuery<string | undefined>('search', undefined)

  const concessionShopData = useState<{
    shopData: any
    isInitialised: boolean
    cinema: Cinema | undefined
  }>('concessionShopData', () => ({
    shopData: undefined,
    isInitialised: false,
    cinema: undefined,
  }))

  const { data, execute } = await useAsyncGql(
    'FetchConcessionShop',
    {
      cinemaId: cinema?.id,
      type,
    },
    { immediate: false },
  )

  if (!concessionShopData.value.isInitialised && !!cinema) {
    await execute()

    const { cart, fetchCart } = await useConcessionCart({
      cinema,
    })

    if (!cart.value && route.query.cartId) {
      await fetchCart(route.query.cartId as string)
    }

    concessionShopData.value = {
      shopData: data.value.concessionShop,
      isInitialised: true,
      cinema,
    }
  }

  function matchesConcession(concession: Concession, searchTerm: string) {
    return (
      concession.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (concession.description?.toLowerCase() || '').includes(
        searchTerm.toLowerCase(),
      )
    )
  }

  const filteredShopData = computed<ConcessionShop | undefined>(() => {
    if (!concessionShopData.value.shopData) {
      return undefined
    }

    if (!search.value) {
      return concessionShopData.value.shopData
    }

    const { categories = [], concessions = [] } =
      concessionShopData.value.shopData

    return {
      concessions: concessions.filter((concession: Concession) =>
        matchesConcession(concession, search.value!),
      ),

      categories: categories.flatMap(
        (category: ConcessionCategory | ConcessionMainCategory) => {
          if ('subCategories' in category) {
            const matchingSubCategories = category.subCategories
              ?.filter(
                (subCategory: ConcessionCategory | ConcessionMainCategory) =>
                  subCategory.concessions?.some((concession: Concession) =>
                    matchesConcession(concession, search.value!),
                  ),
              )
              ?.map(
                (subCategory: ConcessionCategory | ConcessionMainCategory) => ({
                  ...subCategory,
                  concessions: subCategory.concessions?.filter(
                    (concession: Concession) =>
                      matchesConcession(concession, search.value!),
                  ),
                }),
              )

            return matchingSubCategories?.length
              ? [{ ...category, subCategories: matchingSubCategories }]
              : []
          }

          const matchingConcessions = category.concessions?.filter(
            (concession: Concession) =>
              matchesConcession(concession, search.value!),
          )

          return matchingConcessions?.length
            ? [{ ...category, concessions: matchingConcessions }]
            : []
        },
      ),
    }
  })

  return {
    search,
    shopData: filteredShopData,
    cinema: computed<Cinema | undefined>(() => concessionShopData.value.cinema),
    isInitialised: computed(() => concessionShopData.value.isInitialised),
  }
}
