import type {
  CartConcessionInput,
  Cart,
  CartType,
  Cinema,
  CartConcession,
  Concession,
} from '#gql/default'
import { CART_TYPE } from '@booking/constants/cart'

export default async function useConcessionCart({
  cinema,
}: {
  cinema: Cinema
}) {
  const { add: addMessage } = useMessage()
  const {
    cart,
    fetchCart,
    ensureCart,
    ensureCartStrings,
    pending,
    getMessage,
  } = await useCart()

  await ensureCartStrings({ cinema })

  async function patchConcessions(
    concessions: CartConcessionInput[],
  ): Promise<Cart> {
    pending.value = true
    try {
      let cartId = cart.value?.id

      if (!cartId) {
        const { id } = await ensureCart({
          cinema,
          type: CART_TYPE.SHOP as CartType,
        })
        cartId = id
      }

      const result = await GqlCartPatchConcessions({
        cartId,
        concessions,
      })

      const updatedCart = result.cartPatchConcessions as Cart
      cart.value = updatedCart

      return updatedCart
    } catch (error) {
      throw new Error(error as string)
    } finally {
      pending.value = false
    }
  }

  function getConcessionItems(concessionId: string): CartConcession[] {
    if (!cart.value || !cart.value.concessions) {
      return []
    }

    return cart.value.concessions.filter(
      (item) => item.concession.id === concessionId,
    )
  }

  function getConcessionQuantity(concessionId: string): number {
    const items = getConcessionItems(concessionId)
    return items.reduce((total, item) => total + item.quantity, 0)
  }

  async function addToCart({
    items,
    concession,
  }: {
    items: CartConcessionInput[]
    concession: Concession
  }) {
    try {
      const previousQuantity = getConcessionQuantity(concession.id)
      const newQuantity = items.reduce(
        (total, item) => total + (item.quantity || 0),
        0,
      )

      await patchConcessions(items)

      addMessage({
        message: getMessage({
          newQuantity,
          cartItemId: concession.id,
          productName: concession.name,
          previousQuantity,
        }),
        severity: MESSAGE_SEVERITY.SUCCESS,
        type: MESSAGE_TYPE.TOAST,
      })
    } catch {
      addMessage({
        message: getMessage(),
        severity: MESSAGE_SEVERITY.ERROR,
        type: MESSAGE_TYPE.TOAST,
      })
    }
  }

  return {
    cart,
    fetchCart,
    patchConcessions,
    addToCart,
    pending,
    getConcessionItems,
    getConcessionQuantity,
  }
}
