<script setup lang="ts">
import { BUTTON_THEME } from '@kh-ui/constants/button'

interface Props {
  quantity: number
  showEdit?: boolean
  showAdd?: boolean
}

const { t } = useI18n()
const { icons } = useDesign()
const { quantity, showEdit = true } = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update', payload: { quantity: number }): void
  (e: 'edit'): void
}>()

function handleSubmit({ quantity }: { quantity: number }) {
  emit('update', {
    quantity,
  })
}

defineOptions({
  name: 'CartFormUpdateItem',
})
</script>

<template>
  <div>
    <FormKit
      type="form"
      :actions="false"
      :classes="{ form: 'flex justify-between w-full' }"
      @submit="handleSubmit"
    >
      <KButton
        v-if="showEdit"
        :text="t('btn.edit')"
        :theme="BUTTON_THEME.SECONDARY"
        class="inline-flex"
        @click="emit('edit')"
      />

      <div>
        <Transition name="fade" mode="out-in">
          <KButton
            v-if="showAdd && !quantity"
            :title="t('btn.add')"
            :icon="icons.CART_OUTLINE"
            :theme="BUTTON_THEME.SECONDARY"
            class="inline-flex"
            @click="handleSubmit({ quantity: 1 })"
          />

          <FormKit
            v-else-if="!showAdd || quantity"
            type="number"
            name="quantity"
            :deletable="true"
            :step="1"
            :min="1"
            :delay="300"
            :value="quantity"
            @input="
              (value: string) => handleSubmit({ quantity: Number(value) })
            "
          />
        </Transition>
      </div>
    </FormKit>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<i18n>
de:
  btn:
    edit: "Bearbeiten"
    add: "Hinzufügen"
en:
  btn:
    edit: "Edit"
    add: "Add"
es:
  btn:
    edit: "Editar"
    add: "Añadir"
</i18n>
