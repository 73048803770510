<script setup lang="ts">
import type { ConcessionOrder } from '#gql/default'

interface Props {
  order: ConcessionOrder
}

const { order } = defineProps<Props>()

const { icons } = useDesign()
const { ct } = useCinemaStrings()

const { STATUS_IN_PREPARATION } = ORDER_CONCESSIONS_CINEMA_STRINGS

defineOptions({
  name: 'OnSiteConcessionsCardStatusInPreparation',
})
</script>

<template>
  <div>
    <Icon :name="icons.HOURGLASS_OUTLINE" class="size-8" />

    <span
      v-text="ct(STATUS_IN_PREPARATION, { pickupCode: order.pickupCode })"
    />
  </div>
</template>
