<script setup lang="ts">
import type { Cinema } from '#gql/default'
import { CONCESSION_SHOP_PANEL } from '@concession-shop/constants/concession-shop'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()
const { t } = useI18n()
const { cart } = await useConcessionCart({
  cinema,
})

const hasItemsOnCart = computed(() => cart.value?.concessions.length)

const { setPanel } = useConcessionShopPanels()
</script>

<template>
  <Panel>
    <template #default="{ cssClasses }">
      <div :class="cssClasses.grid">
        <div :class="[cssClasses.content]">
          <ConcessionCategories />
          <ConcessionCards :cinema />
        </div>

        <CartActions
          :class="[cssClasses.sidebar]"
          @load-cart="setPanel(CONCESSION_SHOP_PANEL.CART)"
          @load-checkout="setPanel(CONCESSION_SHOP_PANEL.CHECKOUT)"
        />
      </div>
    </template>
  </Panel>
</template>
