<script lang="ts" setup>
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()

useLegacyWidget({
  iframeName: 'kinoheld-widget',
  cinemaPath: `kino-${cinema.city.urlSlug}/${cinema.urlSlug}/vouchers`,
  floatingCart: false,
})

defineOptions({
  name: 'VoucherShopLegacyWidget',
})
</script>

<template>
  <div>
    <div id="kinoheld-eframe" />
  </div>
</template>
