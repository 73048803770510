<template>
  <div>
    <div v-if="headline || description" class="mb-8">
      <h3
        v-if="headline"
        class="text-lg font-bold leading-tight"
        v-html="headline"
      />
      <div v-html="description" />
    </div>
  </div>
  <div
    data-voucher-product-cards
    class="group-data-[default-horizontal-spacing=true]:container-bleed"
  >
    <HorizontalMediaScrollerNew :slides="voucherProducts" :responsive-behaviour>
      <template #default="{ slide: voucherProduct }">
        <VoucherProductCard
          :key="voucherProduct.id"
          :voucher-product
          data-image
        />
      </template>
    </HorizontalMediaScrollerNew>
  </div>
</template>

<script lang="ts" setup>
import type { VoucherProduct } from '#gql/default'

interface Props {
  voucherProducts: VoucherProduct[]
  renderSkeletons?: number
  headline?: string
  description?: string
}

const responsiveBehaviour = computed(() => ({
  [TAILWIND_CONTAINER_BREAKPOINT.BREAKPOINT_XS]: {
    slidesWidth: 300,
    gapSize: 16,
  },
  [TAILWIND_CONTAINER_BREAKPOINT.BREAKPOINT_XL]: {
    slidesWidth: 280,
    gapSize: 16,
  },
  [TAILWIND_CONTAINER_BREAKPOINT.BREAKPOINT_2XL]: {
    slidesPerPage: 2,
    gapSize: 16,
  },
  [TAILWIND_CONTAINER_BREAKPOINT.BREAKPOINT_3XL]: {
    slidesPerPage: 3,
    gapSize: 16,
  },
}))

defineProps<Props>()

defineOptions({
  name: 'VoucherProductCards',
})
</script>
