<script setup lang="ts">
import type { ConcessionOrder } from '#gql/default'

interface Props {
  order: ConcessionOrder
}

const { order } = defineProps<Props>()

const { d } = useI18n()
const { removeOrderById, ensureOrderConcessionsStrings } = useOrderConcessions()

await ensureOrderConcessionsStrings({ cinema: order.cinema })

const orderDateTime = computed(() => {
  if (order.createdAt) {
    return d(order.createdAt, {
      weekday: 'short',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  return undefined
})

defineOptions({
  name: 'OnSiteConcessionsCard',
})
</script>

<template>
  <div
    class="border-neutral-light-1 dark:border-neutral-dark-1 flex flex-col gap-2 rounded-lg border p-4"
  >
    <div class="flex flex-row items-center justify-between">
      <span v-text="order.pickupCode" class="text-xl font-bold" />
      <ButtonClose @click="removeOrderById(order.id)" />
    </div>

    <span v-if="orderDateTime" v-text="orderDateTime" />

    <ul class="list-inside list-disc">
      <li v-for="(concession, index) in order.concessions" :key="index">
        {{ concession.concession.name }}
      </li>
    </ul>

    <OnSiteConcessionsCardStatus :order />
  </div>
</template>
