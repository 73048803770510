<script setup lang="ts">
import type { ConcessionSubItem } from '#gql/default'

interface Props {
  subItem: ConcessionSubItem
  initialValue?: string
}

const { subItem, initialValue } = defineProps<Props>()
const { t } = useI18n()

const options = computed(() => [
  {
    label: t('select'),
    value: '',
  },
  ...subItem.options.map((option) => ({
    label: option?.name || '',
    value: option?.id || '',
  })),
])
</script>

<template>
  <FormKit
    v-if="!subItem?.options.length"
    type="hidden"
    :name="subItem.id"
    value=""
  />
  <FormKit
    v-else
    type="select"
    :floating-label="true"
    validation="required"
    :name="subItem.id"
    :label="subItem.name"
    :options="options"
    :value="initialValue"
  />
</template>

<i18n>
en:
  select: "Please select"
de:
  select: "Bitte wählen"
es:
  select: "Seleccionar opción"
</i18n>
