<script setup lang="ts">
import type { CartConcession } from '#gql/default'

interface Props {
  concession: CartConcession
}

const { concession } = defineProps<Props>()
const { n } = useI18n()

defineOptions({
  name: 'CartOverviewConcession',
})
</script>

<template>
  <div class="grid grid-cols-[minmax(0,1fr),auto] gap-x-2 gap-y-1 py-2">
    <div
      class="truncate"
      v-text="
        formatQuantityAndName(concession.quantity, concession.concession.name)
      "
    />
    <div v-text="n(concession.price.total, 'currency')" />
    <div
      v-if="!!concession.subItemSelection"
      v-text="formatSubItemSelection(concession.subItemSelection)"
      class="dark:text-neutral-light-1 text-neutral-dark-1 col-span-2 text-xs"
    />
  </div>
</template>
