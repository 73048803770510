<script setup lang="ts">
import type { VoucherShopStrings } from '#gql/default'
import {
  VOUCHER_PRODUCT_TYPE,
  VOUCHER_SHOP_PANEL,
} from '@voucher-shop/constants/voucher-shop'
const { CONFIGURE } = VOUCHER_SHOP_PANEL

interface Props {
  strings?: VoucherShopStrings
}

const { strings } = defineProps<Props>()
const { setPanel, voucherProducts } = await useVoucherShop()
const panelId = useId()

usePanel({
  id: panelId,
})

const fixedProducts = computed(() =>
  voucherProducts.value.filter(
    ({ type }: { type: string }) => type === VOUCHER_PRODUCT_TYPE.FIXED,
  ),
)

const ticketProducts = computed(() =>
  voucherProducts.value.filter(
    ({ type }: { type: string }) => type === VOUCHER_PRODUCT_TYPE.TICKETS,
  ),
)

// Add watch to handle async data loading
watch(
  voucherProducts,
  (newProducts) => {
    if (newProducts.length === 1) {
      const voucher = newProducts[0]
      setPanel(CONFIGURE, { voucherId: voucher.id })
    }
  },
  { immediate: true },
)

defineOptions({
  name: 'VoucherShopPanelSelect',
})
</script>

<template>
  <Panel :id="panelId" data-panel-select>
    <template #header>
      <slot name="header" />
    </template>

    <VoucherProductCards
      v-if="fixedProducts.length > 0"
      :voucher-products="fixedProducts"
      :headline="strings?.voucherProductsFixedHeadline ?? ''"
      :description="strings?.voucherProductsFixedDescription ?? ''"
    />

    <VoucherProductCards
      v-if="ticketProducts.length > 0"
      :voucher-products="ticketProducts"
      :headline="strings?.voucherProductsTicketsHeadline ?? ''"
      :description="strings?.voucherProductsTicketsDescription ?? ''"
    />
  </Panel>
</template>
