<script lang="ts" setup>
const route = useRoute()
const { page } = useCms()

const { city } = await useCity({
  preselectedCitySlug: route.params.citySlug as string,
})

defineOptions({
  name: 'PageCinemaCitySlugIndex',
})
</script>

<template>
  <div class="contents">
    <CmsPage v-if="page" :page />
    <GenericPageCity v-else :city :distance="25" />
  </div>
</template>
